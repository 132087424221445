import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '../../utils/createFetchReducer';
import { sendStorytellersTypes } from './actions';
import { initialState } from './constants';

const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(sendStorytellersTypes),
		// ...createFetchReducer(
		//   authWorkspaceLoginTypes,
		//   workspaceAndLoginSuccessMapper
		// ),
	},
});

export default onboardingSlice.reducer;
