import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import fileDownload from 'js-file-download';

import {
	filterStorytellersTypes,
	filterStorytellerPendingInvitesTypes,
	filterAdminsTypes,
	filterAdminPendingInvitesTypes,
	createStorytellerTypes,
	updateStorytellerTypes,
	importStorytellersTypes,
	verifyStorytellerTypes,
	resendStorytellerVerificationTypes,
	exportStorytellersTypes,
	sendSmsAppLinkTypes,
	getStorytellerOptionsTypes,
	uploadStorytellerProfilePictureType,
	bulkSoftDeleteStorytellersType,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import ObjectToOptions from '../../utils/ObjectToOptions';

const storyteller = entityNormalizer('storytellers');
const pagination = { storytellers: [storyteller] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	storytellerOptions: [],
	storytellers: {},
	storytellersCount: 0,
	storytellerPendingInvites: {},
	storytellerPendingInvitesCount: 0,
	admins: {},
	adminsCount: 0,
	adminPendingInvites: {},
	adminPendingInvitesCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
		count: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type storytellersStateType = typeof initialState;
function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			storytellers,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ storytellers, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function optionsMapper(state: storytellersStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	const storytellerCopy = ObjectToOptions(
		normalizedData.entities.storytellers,
		'id',
		'fullName',
	);
	storytellerCopy.unshift({
		value: '',
		label: 'All',
	});
	state.storytellerOptions = storytellerCopy;
}
function paginationMapper(
	state: storytellersStateType,
	action: ResponseAction,
) {
	const normalizedData = normalizeResponse(action);

	if (action.response.page === 1) {
		state.storytellers = normalizedData.entities.storytellers;
	} else {
		state.storytellers = {
			...state.storytellers,
			...normalizedData.entities.storytellers,
		};
	}
	/** This is to avoid multiple loops in the app to count the storytellers
	 * for the infinite scroll*/
	state.storytellersCount = Object.keys(state.storytellers || {})?.length;
	state.pagination.pages[action.response.page] =
		normalizedData.result.storytellers;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
}

function adminPaginationMapper(
	state: storytellersStateType,
	action: ResponseAction,
) {
	const normalizedData = normalizeResponse(action);

	state.admins = normalizedData.entities.storytellers;

	/** This is to avoid multiple loops in the app to count the storytellers
	 * for the infinite scroll*/
	state.adminsCount = action.response.count || 0;
}

function storytellerPendingInvitesMapper(
	state: storytellersStateType,
	action: ResponseAction,
) {
	const normalizedData = normalizeResponse(action);

	state.storytellerPendingInvites = normalizedData.entities.storytellers;

	/** This is to avoid multiple loops in the app to count the storytellers
	 * for the infinite scroll*/
	state.storytellerPendingInvitesCount = action.response.count || 0;
}

function adminPendingInvitesMapper(
	state: storytellersStateType,
	action: ResponseAction,
) {
	const normalizedData = normalizeResponse(action);

	state.adminPendingInvites = normalizedData.entities.storytellers;

	/** This is to avoid multiple loops in the app to count the storytellers
	 * for the infinite scroll*/
	state.adminPendingInvitesCount = action.response.count || 0;
}

function createStorytellerMapper(
	state: storytellersStateType,
	action: ResponseAction,
) {
	const { custom } = action.payload;
	const { storyteller } = action.response;

	if (custom !== 'resendInvitation') {
		const newStoryteller = { [storyteller._id]: storyteller };
		state.storytellers = { ...newStoryteller, ...state.storytellers };
	}
}

function importStorytellersMapper(
	state: storytellersStateType,
	action: ResponseAction,
): void {
	state.type = action.type;
}

const verifyStorytellerSucessMapper = (
	state: storytellersStateType,
	action: ResponseAction,
) => {
	const { storyteller } = action.response;

	if (state.storytellersCount && state.storytellers[storyteller._id]) {
		state.storytellers[storyteller._id].senderIdentities =
			storyteller.senderIdentities;
		return;
	}

	if (state.adminsCount && state.admins[storyteller._id]) {
		state.admins[storyteller._id].senderIdentities =
			storyteller.senderIdentities;
		return;
	}
};

export const exportStorytellersSuccessMapper = (
	state: storytellersStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	fileDownload(response, 'storytellers.csv');
	state.type = action.type;
};

const storytellerSlice = createSlice({
	name: 'storyteller',
	initialState,
	reducers: {
		RESET(state: storytellersStateType) {
			state.response = initialState.response;
			state.error = initialState.error;
		},
		MANUALLY_ADD_STORYTELLER(state: storytellersStateType, action: any) {
			const newStorytellerId = action.payload._id;
			const newStorytellers = {
				...state.storytellers,
				[newStorytellerId]: action.payload,
			};
			state.storytellers = newStorytellers;
		},
		SET_VERIFICATION_LOADING(state: storytellersStateType, action: any) {
			const loading = action.payload;
			state.verificationLoading = loading;
		},
		CLEAR_STORYTELLERS(state: storytellersStateType) {
			state.storytellers = {};
			state.storytellersCount = 0;
		},
	},
	extraReducers: {
		...createFetchReducer(filterStorytellersTypes, paginationMapper),
		...createFetchReducer(
			filterStorytellerPendingInvitesTypes,
			storytellerPendingInvitesMapper,
		),
		...createFetchReducer(filterAdminsTypes, adminPaginationMapper),
		...createFetchReducer(
			filterAdminPendingInvitesTypes,
			adminPendingInvitesMapper,
		),
		...createFetchReducer(createStorytellerTypes, createStorytellerMapper),
		...createFetchReducer(updateStorytellerTypes, createStorytellerMapper),
		...createFetchReducer(importStorytellersTypes, importStorytellersMapper),
		...createFetchReducer(
			verifyStorytellerTypes,
			verifyStorytellerSucessMapper,
		),
		...createFetchReducer(resendStorytellerVerificationTypes),
		...createFetchReducer(
			exportStorytellersTypes,
			exportStorytellersSuccessMapper,
		),
		...createFetchReducer(sendSmsAppLinkTypes),
		...createFetchReducer(getStorytellerOptionsTypes, optionsMapper),
		...createFetchReducer(uploadStorytellerProfilePictureType),
		...createFetchReducer(bulkSoftDeleteStorytellersType),
	},
});

export default storytellerSlice.reducer;
