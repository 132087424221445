import { createSlice } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	filterCampaignsTypes,
	createCampaignTypes,
	updateCampaignTypes,
	createDraftCampaignTypes,
	launchDraftCampaignTypes,
	exportCampaignsTypes,
	exportSingleCampaignTypes,
	addContactsToCampaignTypes,
	emailPreviewTypes,
	resendStoriesCounttypes,
	resendCampaignStoriesTypes,
	getCampaignOptionsTypes,
	getCampaignTypes,
	getCampaignsCountTypes,
	saveCampaignTypes,
	launchCampaignTypes,
	saveCampaignBulkTypes,
	saveCurrentChildCampaignTypes,
	saveAndUpdateCurrentChildCampaignTypes,
	deactivateCampaignTypes,
	getEmailTemplatesTypes,
	duplicateCampaignTypes,
	bulkDeleteDraftCampaignsTypes,
	getVerifiedStorytellersTypes,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import objectToOptions from '../../utils/ObjectToOptions';

import { ServiceResponse, ToastMessage } from '../../constants';
import { recipesStateType } from '../recipe';
import { CampaignStateEnum, CampaignType } from './types';
import { setStatusChangeInCampaigns } from './utils';
import { showToast } from '../../../common/components/presentation/Toast';

const campaign = entityNormalizer('campaigns', {}, { idAttribute: '_id' });

const pagination = { campaigns: [campaign] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	campaign: {},
	campaigns: {},
	campaignCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	paginationOptions: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	updateResponse: {
		status: null,
		message: null,
	},
	createDraftResponse: {
		status: null,
		message: null,
	},
	launchResponse: {
		status: null,
		message: null,
	},
	custom: {},
	campaignOptions: [],
	savedCampaign: {},
	campaignTree: {},
	campaignName: 'Untitled Campaign',
	totalCampaigns: {},
	verifiedStorytellers: [],
};

export type campaignsStateType = typeof initialState;

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			campaigns,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ campaigns, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function paginationMapper(state: campaignsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);

	//to make the infinite scroll works
	if (action.response.page === 1) {
		state.campaigns = {
			...normalizedData.entities.campaigns,
		};
	} else {
		state.campaigns = {
			...state.campaigns,
			...normalizedData.entities.campaigns,
		};
	}

	state.campaignCount = Object.keys(state.campaigns || {})?.length;
	//Options for the dropdown menu
	state.pagination.pages[action.response.page] =
		normalizedData.result.campaigns;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
}

function getCampaign(state: campaignsStateType, action: ResponseAction) {
	const { campaign, sharedVideoStats, contactsByPreference } = action.response;
	state.campaign = {
		...campaign,
		sharedVideoStats,
		contactsByPreference,
	};
}

function optionsMapper(state: campaignsStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	const campaignCopy = objectToOptions(
		normalizedData.entities.campaigns,
		'id',
		'name',
	);
	if (!action.payload?.excludeAll && !action.payload.includeBoth) {
		campaignCopy.unshift({
			value: '',
			label: 'All',
		});
	}

	if (action?.response?.page > 1) {
		state.campaignOptions = [...state.campaignOptions, ...campaignCopy];
	} else {
		state.campaignOptions = campaignCopy;
	}
	if (action.payload.includeBoth) {
		state.campaignOptions = campaignCopy;
		state.campaignOptionsAll = campaignCopy;
		state.campaignOptionsAll = [
			{
				value: '',
				label: 'All',
			},
			...campaignCopy,
		];
	}
	state.paginationOptions.pages[action.response.page] =
		normalizedData.result.campaigns;
	state.paginationOptions.currentPage = action.response.page;
	state.paginationOptions.totalPages = action.response.totalPages;
	state.paginationOptions.count = action.response.count;
}

function getCampaignsCountMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.totalCampaigns = action.response.campaigns;
}

function updateCampaignMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.campaign = { ...state.campaign, ...action.response.campaign };
	state.updateResponse = action.response;
}

function deactivateCampaignMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	const updatedCampaign = action.response.campaign;
	state.campaign.state = updatedCampaign.state;
	state.campaign.pendingStories = updatedCampaign.pendingStories;
	state.campaign.pendingStoriesCount = updatedCampaign.pendingStoriesCount;
	state.campaign.assignedStories = updatedCampaign.assignedStories;
	state.campaign.assignedStoriesCount = updatedCampaign.assignedStoriesCount;
}

function bulkDeleteDraftCampaignsMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	const { campaignIds } = action.response;

	const result = campaignIds.reduce(
		(objToReturn: any, id: string) => {
			const { [id]: deletedCampaign, ...rest } = objToReturn;
			return { ...rest };
		},
		{ ...state.campaigns },
	);

	state.campaigns = result;

	const newCampaignsCount = state.campaignCount - campaignIds.length;
	state.campaignCount = newCampaignsCount > 0 ? newCampaignsCount : 0;
}

function createDraftCampaignSuccessMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.createDraftResponse.message = ServiceResponse.saveDraft;
	state.createDraftResponse = action.response;
}
function createDraftCampaignErrorMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.createDraftResponse.status = action.error.response.status;
	state.createDraftResponse.message = action.error.message;
	state.createDraftResponse.error = action.error;
}

function launchDraftCampaignMapper(
	state: campaignsStateType,
	action: ResponseAction,
) {
	state.launchResponse = action.response;
	state.launchResponse.error = action.error;
}

export const exportCampaignsSuccessMapper = (
	state: campaignsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	fileDownload(response, 'campaigns.csv');
	state.type = action.type;
};

export const exportSingleCampaignSuccessMapper = (
	state: campaignsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	const { campaignName } = action.payload;
	fileDownload(response, `${campaignName}.csv`);
	state.type = action.type;
};

export const addContactToCampaignSuccessMapper = (
	state: campaignsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
};

function updateTreeCampaign(state: recipesStateType, action: ResponseAction) {
	if (!!state.campaignTree[action?.response?.campaign?._id]) {
		state.campaignTree = {
			...state.campaignTree,
			[action.response.campaign._id]: action.response.campaign,
		};
	}
}

function saveCampaignMapper(state: recipesStateType, action: ResponseAction) {
	updateTreeCampaign(state, action);
	state.savedCampaign = {
		...state.savedCampaign,
		...action?.response?.campaign,
		verifiedStorytellers: [],
	};
	state.campaignName = action?.response?.campaign.name;
}

function normalizeCampaignTree(campaigns: CampaignType[]) {
	const entityNormalizer = normalize({ campaigns }, pagination);
	return { ...entityNormalizer.entities.campaigns };
}

function saveCurrentChildCampaignMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	updateTreeCampaign(state, action);
}

function launchCampaignMapper(state: recipesStateType, action: ResponseAction) {
	const currentCampaign = state.savedCampaign;
	const campaignTree = state.campaignTree;

	if (campaignTree[currentCampaign?._id]) {
		const campaignTreeUpdated = setStatusChangeInCampaigns(
			campaignTree,
			CampaignStateEnum.active,
		);

		state.campaignTree = normalizeCampaignTree(campaignTreeUpdated);
	}

	state.savedCampaign = {
		...state.savedCampaign,
		state: CampaignStateEnum.active,
	};
}

function emailPreviewErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorMessage = action?.error?.response?.data?.message;
	const isCtaNotDefined = errorMessage.includes('cta must be defined');
	if (isCtaNotDefined) {
		return showToast(ToastMessage.ctaNotSelected, 'error');
	} else {
		return showToast(ToastMessage.error, 'error');
	}
}

function saveCampaignErrorMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const errorMessage = action?.error?.response?.data?.message;
	const haveMaxNumberOfContacts = errorMessage.includes(
		'Maximun number of contacts exceed.',
	);
	if (haveMaxNumberOfContacts) {
		return showToast(ToastMessage.audienceMaxContactError, 'error');
	} else {
		return showToast(ToastMessage.error, 'error');
	}
}

function verifiedStorytellersMapper(
	state: recipesStateType,
	action: ResponseAction,
) {
	const {
		response: { sendingAddresses },
	} = action;
	state.verifiedStorytellers = sendingAddresses;
}

const campaignSlice = createSlice({
	name: 'campaign',
	initialState,
	reducers: {
		RESET(state: campaignsStateType) {
			state.campaignCount = 0;
			state = initialState;
		},
		EDIT_CAMPAIGN(state: campaignsStateType, action: ResponseAction) {
			state.savedCampaign = {
				...action.payload.campaign,
				isDraft: !!action.payload.campaign._id,
			};
			state.campaignName = action.payload?.campaign.name || 'Untitled Campaign';
		},
		SET_CAMPAIGN_TREE(state: campaignsStateType, action: ResponseAction) {
			const { payload } = action;
			state.campaignTree = normalizeCampaignTree(payload);
		},
		SET_CAMPAIGN_NAME(state: campaignsStateType, action: { payload: string }) {
			state.campaignName = action.payload;
		},
	},
	extraReducers: {
		...createFetchReducer(getCampaignTypes, getCampaign),
		...createFetchReducer(getCampaignsCountTypes, getCampaignsCountMapper),
		...createFetchReducer(filterCampaignsTypes, paginationMapper),
		...createFetchReducer(
			createDraftCampaignTypes,
			createDraftCampaignSuccessMapper,
			createDraftCampaignErrorMapper,
		),
		...createFetchReducer(launchDraftCampaignTypes, launchDraftCampaignMapper),
		...createFetchReducer(createCampaignTypes),
		...createFetchReducer(updateCampaignTypes, updateCampaignMapper),
		...createFetchReducer(deactivateCampaignTypes, deactivateCampaignMapper),
		...createFetchReducer(exportCampaignsTypes, exportCampaignsSuccessMapper),
		...createFetchReducer(
			exportSingleCampaignTypes,
			exportSingleCampaignSuccessMapper,
		),
		...createFetchReducer(
			addContactsToCampaignTypes,
			addContactToCampaignSuccessMapper,
		),
		...createFetchReducer(emailPreviewTypes, () => {}, emailPreviewErrorMapper),
		...createFetchReducer(getEmailTemplatesTypes),
		...createFetchReducer(
			getVerifiedStorytellersTypes,
			verifiedStorytellersMapper,
		),
		...createFetchReducer(resendStoriesCounttypes),
		...createFetchReducer(resendCampaignStoriesTypes),
		...createFetchReducer(getCampaignOptionsTypes, optionsMapper),
		...createFetchReducer(
			saveCampaignTypes,
			saveCampaignMapper,
			saveCampaignErrorMapper,
		),
		...createFetchReducer(launchCampaignTypes, launchCampaignMapper),
		...createFetchReducer(saveCampaignBulkTypes),
		...createFetchReducer(
			saveCurrentChildCampaignTypes,
			saveCurrentChildCampaignMapper,
		),
		...createFetchReducer(
			saveAndUpdateCurrentChildCampaignTypes,
			saveCampaignMapper,
		),
		...createFetchReducer(duplicateCampaignTypes),
		...createFetchReducer(
			bulkDeleteDraftCampaignsTypes,
			bulkDeleteDraftCampaignsMapper,
		),
	},
});

export default campaignSlice.reducer;
