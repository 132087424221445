import createFetchTypes from '../../utils/createFetchTypes';
import { fetchAction, GenericAPIResponse } from '../types';
import axios from '../../../global/axios';
import { apiUrl, ToastMessage } from '../../constants';

const mainType = 'workspace';

export type Workspace = {
	name: string;
	logo: string;
	_id: string;
	__v: number;
	id: string;
};

type ResponseType = GenericAPIResponse & {
	workspaces: Workspace[];
};

export const getWorkspaceByEmailTypes = createFetchTypes(
	`${mainType}_GET_WORKSPACES_BY_EMAIL`,
);
export const updateWorkspaceGatewayTypes = createFetchTypes(
	`${mainType}_GATEWAY_UPDATE`,
);
export const WorkspaceInfoTypes = createFetchTypes(
	`${mainType}_WORKSPACE_INFO`,
);
export const zapierKeyTypes = createFetchTypes(`${mainType}_ZAPIER_KEY`);

export const AddWorkspaceGatewayTypes = createFetchTypes(
	`${mainType}_WORKSPACE_GATEWAY`,
);
export const DisconnectBlackbaudGatewayTypes = createFetchTypes(
	`${mainType}_WORKSPACE_GATEWAY`,
);
export const disconnectWorkspaceIntegrationTypes = createFetchTypes(
	`${mainType}_DISCONNECT_INTEGRATION`,
);
export const updateWorkspaceIntegrationTypes = createFetchTypes(
	`${mainType}_WORKSPACE_INTEGRATION`,
);
export const updateHotglueCommunicationPreferenceTypes = createFetchTypes(
	`${mainType}_HOTGLUE_COMMUNICATION_PREFRENCE`,
);
export const updateHotglueSyncingScheduleTypes = createFetchTypes(
	`${mainType}_HOTGLUE_SYNCING_SCHEDULE`,
);
export const uploadLogoTypes = createFetchTypes(`${mainType}_UPLOAD_LOGO`);
export const updateWithLogoAndBackgroundtypes = createFetchTypes(
	`${mainType}_UPLOAD_LOGO_BACKGROUND`,
);
export const updateWorkspaceTypes = createFetchTypes(`${mainType}_UPDATE`);
export const verifyWorkspaceDomainDataTypes = createFetchTypes(
	`${mainType}_SET_DOMAIN_DATA`,
);
export const verifyDomainTypes = createFetchTypes(`${mainType}_VERIFY_DOMAIN`);

export const setZapierIntegrationTypes = createFetchTypes(
	`${mainType}_SET_ZAPIER_INTEGRATION`,
);

export const disconnectHotglueIntegrationTypes = createFetchTypes(
	`${mainType}_DISCONNECT_HOTGLUE_INTEGRATION`,
);

export const updateCustomAttributesTypes = createFetchTypes(
	`${mainType}_UPDATE_CUSTOM_ATTRIBUTES`,
);

export const connectHotglueFlowTypes = createFetchTypes(
	`${mainType}_CONNECT_HOTGLUE_FLOW`,
);

export const getSendDataOutTargetFieldsTypes = createFetchTypes(
	`${mainType}_GET_SEND_DATA_OUT_TARGET_FIELD`,
);

function setZapierIntegration(): fetchAction {
	return {
		type: setZapierIntegrationTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/integration/zapier`),
		payload: {
			successMessage: ToastMessage.integrationConnected,
		},
	};
}

function getWorkspaceByEmail(email: string): fetchAction {
	return {
		type: getWorkspaceByEmailTypes,
		callAPI: () => axios.get(`${apiUrl}/auth/workspaces?e=${email}`),
	};
}

function updateWorkspaceGateway({ workspaceId, ...obj }: any): fetchAction {
	return {
		type: updateWorkspaceGatewayTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/gateway/${workspaceId}`, obj),
		payload: {
			successMessage: ToastMessage.gatewayConnected,
		},
	};
}

function DisconnectGateway(obj: any): fetchAction {
	const data = {
		gatewayOn: false,
		gatewayId: obj.gatewayId,
		key: obj.publicKey || obj.key || 'null',
		name: obj.name || 'blackbaud',
	};

	return {
		type: DisconnectBlackbaudGatewayTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/gateway/${obj.workspaceId}`, data),
		payload: {
			successMessage: ToastMessage.gatewayDisconnected,
		},
	};
}

// function getWorkspaceInfo(): fetchAction {
//     return {
//         type: WorkspaceInfoTypes,
//         callAPI: () => axios.get(`${apiUrl}/auth/verify`)
//     }
// }

function updateWorkspaceIntegration(obj: any): fetchAction {
	const data: { [key: string]: string } = {
		integrationId: obj.integrationId,
		integrationName: obj.integrationName || 'blackbaud',
		integrationBaseUrl: obj.integrationBaseUrl || obj.constituent_id,
		integrationOn: obj.integrationOn || true,
	};
	if (obj.refreshToken) {
		data.refreshToken = obj.refreshToken || '';
	}

	return {
		type: updateWorkspaceIntegrationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/${obj.workspaceId}`, data),
		payload: {
			successMessage: ToastMessage.integrationConnected,
		},
	};
}

function connectHotglueFlow(data: { [key: string]: any }): fetchAction {
	return {
		type: connectHotglueFlowTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaces/integration/hotglue/connect`, data),
		payload: {
			successMessage: ToastMessage.integrationConnected,
		},
	};
}

function disconnectWorkspaceIntegration(obj: any): fetchAction {
	const data = {
		integrationId: obj.integrationId,
		integrationName: obj.integrationName || 'blackbaud',
		integrationOn: false,
	};
	return {
		type: disconnectWorkspaceIntegrationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/${obj.workspaceId}`, data),
		payload: {
			successMessage: ToastMessage.integrationDisconnected,
		},
	};
}

function updateHotglueCommunicationPreference(obj: any): fetchAction {
	const data = {
		flowName: 'contactImport',
		flow: {
			defaultCommunicationPreference: obj.communicationPreference,
		},
	};

	return {
		type: updateHotglueCommunicationPreferenceTypes,
		callAPI: () => axios.put(`${apiUrl}/workspaces/integration/hotglue`, data),
		payload: {
			successMessage: ToastMessage.integrationUpdated,
		},
	};
}

function updateHotglueSyncingSchedule(data: {
	[key: string]: any;
}): fetchAction {
	return {
		type: updateHotglueSyncingScheduleTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/hotglue/scheduleJob`, data),
		payload: {
			successMessage: ToastMessage.integrationUpdated,
		},
	};
}

function getZapierApiKey(): fetchAction {
	return {
		type: zapierKeyTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/integration/zapier`),
	};
}

function getWorkspaceInfo(custom: string = null): fetchAction {
	return {
		type: WorkspaceInfoTypes,
		callAPI: () => axios.get(`${apiUrl}/workspaces/current`),
		payload: {
			...(custom ? { custom } : {}),
		},
	};
}

function updateWorkspace(
	fields: any,
	workspaceId: string,
	successMessage?: string,
): fetchAction {
	return {
		type: updateWorkspaceTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/${workspaceId}`, { workspace: fields }),
		payload: {
			custom: 'updateWorkspace',
			...(successMessage ? { successMessage } : {}),
		},
	};
}

function uploadLogo(logoForm: FormData) {
	return {
		type: uploadLogoTypes,
		callAPI: () => axios.post(`${apiUrl}/workspaces/uploadLogo`, logoForm),
	};
}

function updateWithLogoAndBackground(workspaceForm: FormData) {
	return {
		type: updateWithLogoAndBackgroundtypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/workspaces/logoAndBackground`, workspaceForm),
	};
}

function verifyWorkspaceDomainData() {
	return {
		type: verifyWorkspaceDomainDataTypes,
		callAPI: () => axios.get(`${apiUrl}/workspaces/domain`),
		payload: { custom: 'verifyDomain' },
	};
}

function resetWorkspace() {
	return {
		type: `${mainType}/RESET_WORKSPACE`,
	};
}

function disconnectHotglueIntegration(
	integrationId: string,
	flowName: string,
	custom: string = null,
) {
	return {
		type: disconnectHotglueIntegrationTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/integration/hotglue/disconnect`, {
				integrationId,
				flowName,
			}),
		payload: {
			...(custom ? { custom } : {}),
			successMessage: ToastMessage.integrationDisconnected,
		},
	};
}

function updateCustomAttributes(
	customAttributes?: [{ index: number; fields: { [key: string]: any } }],
) {
	return {
		type: updateCustomAttributesTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/workspaces/customAttributes`, {
				customAttributes,
			}),
		payload: { custom: 'updateCustomAttributes' },
	};
}

function getSendDataOutTargetFields(integrationName: string) {
	return {
		type: getSendDataOutTargetFieldsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/workspaces/hotglue/sendDataOut/targets?targetName=${integrationName}`,
			),
		payload: { custom: 'getSendDataOutTargetFields' },
	};
}

export {
	getWorkspaceByEmail,
	updateWorkspaceGateway,
	getWorkspaceInfo,
	updateWorkspaceIntegration,
	updateHotglueCommunicationPreference,
	updateHotglueSyncingSchedule,
	updateWorkspace,
	DisconnectGateway,
	disconnectWorkspaceIntegration,
	uploadLogo,
	verifyWorkspaceDomainData,
	updateWithLogoAndBackground,
	getZapierApiKey,
	setZapierIntegration,
	resetWorkspace,
	disconnectHotglueIntegration,
	updateCustomAttributes,
	connectHotglueFlow,
	getSendDataOutTargetFields,
};
