import React, { Suspense, lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { datadogRum } from '@datadog/browser-rum';

// import 'semantic-ui-css/semantic.min.css';
// import 'react-toastify/dist/ReactToastify.css';
const CSSLoader = lazy(() => import('./CSSLoader'));

import store from './redux/store';

const Routes = lazy(() => import('./routes'));
// import Routes from './routes';
import config from './authConfig';
import { ToastPosition } from './constants';

import history from './utils/history';

import GoodkindTheme from '../global/theme';

import { GlobalStyle } from './app.styles';
import { sentryKey } from './constants/Sentry';

export default function App(props: AppProps) {
	const onRedirectCallback = (appState: any) => {
		history.push(
			appState && appState.targetUrl
				? appState.targetUrl
				: window.location.pathname + '/Home',
		);
	};

	// explictly have to enable it to avoid local metrics
	// by default case
	if (process.env.ENABLE_DATADOG === 'on') {
		datadogRum.init({
			applicationId: '3d61d234-172e-4fb7-90b3-df082f74dd6c',
			clientToken: 'pub4dbf744de6ec327d735d607a2cf091af',
			site: 'datadoghq.com',
			service: 'webapp',
			env: process.env.NODE_ENV,
			// Specify a version number to identify the deployed version of your application in Datadog
			// version: '1.0.0',
			sampleRate: 100,
			trackInteractions: true,
			// disabling session replay
			// defaultPrivacyLevel: 'mask-user-input'
		});
		// disabling session replay
		// datadogRum.startSessionReplayRecording();
	}

	Sentry.init({
		dsn: sentryKey,
		integrations: [new BrowserTracing()],
		release: 'goodkind-frontend@4.8.1',
		environment: process.env.NODE_ENV,
		tracesSampleRate: 1.0,
	});

	return (
		<>
			<Suspense fallback={<></>}>
				<CSSLoader />
			</Suspense>
			<ThemeProvider theme={GoodkindTheme}>
				<Provider store={store}>
					<GlobalStyle />
					<Auth0Provider
						useRefreshTokens={true}
						cacheLocation={'localstorage'}
						domain={config.domain}
						clientId={config.clientId}
						redirectUri={config.redirectUri}
						onRedirectCallback={onRedirectCallback}
					>
						<Suspense fallback={<></>}>
							<Routes />
						</Suspense>
						<ToastContainer hideProgressBar style={ToastPosition} />
					</Auth0Provider>
				</Provider>
			</ThemeProvider>
		</>
	);
}

type AppProps = {
	/** This property is used for a personalized greeting  */
	// name: string;
};
