import createFetchTypes from '../../utils/createFetchTypes';
import { fetchAction } from '../types';
import axios from '../../../global/axios';
import { apiUrl } from '../../constants';

const mainType = 'AUTH';

export const grantAccessTypes = createFetchTypes(`${mainType}_GRANT_ACCESS`);

function grantAccess(
	workspaceId: string,
	email: string,
	reload = false,
): fetchAction {
	return {
		type: grantAccessTypes,
		payload: { reload },
		callAPI: () =>
			axios.get(`${apiUrl}/auth/adminAccess?w=${workspaceId}&e=${email}`),
	};
}

export const uploadProfilePictureTypes = createFetchTypes(
	`${mainType}_UPLOAD_PROFILE_PICTURE`,
);

function uploadProfilePicture({
	email,
	workspaceId,
	pictureUrl,
}: {
	workspaceId: string;
	email: string;
	pictureUrl: string;
}): fetchAction {
	return {
		type: uploadProfilePictureTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/storytellers/uploadProfilePictureLink`, {
				email,
				pictureUrl,
				workspaceId,
			}),
	};
}

export const verifyTypes = createFetchTypes(`${mainType}_VERIFY`);

function verify() {
	return {
		type: verifyTypes,
		callAPI: () => axios.get(`${apiUrl}/auth/verify`),
	};
}

export const setClientType = 'auth/SET_AUTH_CLIENT';

function setAuthClient(client: any) {
	return {
		type: setClientType,
		client,
	};
}

export { grantAccess, verify, setAuthClient, uploadProfilePicture };
