import { GenericEntityState } from '../../types';
import createFetchTypes from '../../../utils/createFetchTypes';
import entityNormalizer from '../../../utils/entityNormalizer';

const contact = entityNormalizer('contacts');

export const pagination = { contacts: [contact] };

export const mainType = 'contact';

export const exportContactsTypes = createFetchTypes(
	`${mainType}_EXPORT_CONTACTS`,
);

export const exportContactsPerCampaignTypes = createFetchTypes(
	`${mainType}_EXPORT_CONTACTS_PER_CAMPAIGN`,
);

export const filterContactsTypes = createFetchTypes(
	`${mainType}_FILTER_CONTACTS`,
);
export const complexFilterContactstypes = createFetchTypes(
	`${mainType}_COMPLEX_FILTER_CONTACTS`,
);
export const uploadFlatfileContactsTypes = createFetchTypes(
	`${mainType}_UPLOAD_FLATFILE_CONTACTS`,
);

export const updateContactTagTypes = createFetchTypes(
	`${mainType}_UPDATE_CONTACT_TAG`,
);

export const deleteContactTagTypes = createFetchTypes(
	`${mainType}_DELETE_CONTACT_TAG`,
);

export const softDeleteContactsTypes = createFetchTypes(
	`${mainType}_SOFT_DELETE_CONTACTS`,
);

export const filterRepliesActivityTypes = createFetchTypes(
	`${mainType}_REPLIES_ACTIVITY`,
);

export const filterStoriesActivityTypes = createFetchTypes(
	`${mainType}_STORIES_ACTIVITY`,
);

export const getContactsByCampaignIdTypes = createFetchTypes(
	`${mainType}_CAMPAIGN_CONTACTS`,
);

export const initialState: GenericEntityState = {
	loading: false,
	update: true,
	contacts: {},
	contactsCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
	},
	error: {},
	type: '',
	response: null,
	custom: {},
	repliesActivity: [],
	repliesActivityPagination: {},
	storiesActivity: [],
	storiesActivityPagination: {},
};
