import React from 'react';
import { toast } from 'react-toastify';
import CheckmarkAnimation from '../../../../../global/lottie/checkmark.json';
import {
	AnimationContainer,
	CustomLoader,
	ToastMessage,
} from '../Toast.styles';
import Lottie from 'react-lottie';
import ErrorAnimation from '../../../../../global/lottie/error.json';

const showToast = (
	message: string,
	toastType: 'success' | 'error' | 'loading',
) => {
	const ToastComponent = () => {
		let animationData: any = '';
		let viewBoxSize: any = '';
		if (toastType == 'success') {
			animationData = CheckmarkAnimation;
			viewBoxSize = '80 80 80 80';
		} else if (toastType == 'error') {
			animationData = ErrorAnimation;
			viewBoxSize = '';
		}
		const colorFill = '';
		const animationOptions: any = {
			loop: false,
			autoplay: true,
			animationData,
			rendererSettings: {
				preserveAspectRatio: 'xMaxYMax slice',
				// I had to slice it because it had a big padding on both animations
				viewBoxSize,
			},
		};

		function toastContent() {
			if (toastType === 'loading') {
				return <CustomLoader loading width="35px" />;
			} else {
				return (
					<AnimationContainer colorFill={colorFill}>
						<Lottie options={animationOptions} />
					</AnimationContainer>
				);
			}
		}

		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				{toastContent()}
				<ToastMessage>{message}</ToastMessage>
			</div>
		);
	};

	return toast.dark(<ToastComponent />, {
		style: { background: '#313131' },
	});
};

export { showToast };
