import createFetchTypes from '../../utils/createFetchTypes';
import axios from '../../../global/axios';
import { apiUrl, ToastMessage } from '../../constants';
import { AudienceDropdownFilter } from '../constants';

import { fetchAction } from '../types';
import { FilterOptions } from '../../types';

const mainType = 'AUDIENCE';

export const saveAudienceTypes = createFetchTypes(`${mainType}_SAVE_AUDIENCE`);

type saveAudienceObjectType = {
	name: string;
	size?: number;
	filter: string;
};

function saveAudience(
	saveAudienceObject: saveAudienceObjectType,
	custom?: 'uploadAudience',
) {
	return {
		type: saveAudienceTypes,
		callAPI: () => axios.post(`${apiUrl}/audience`, saveAudienceObject),
		payload: {
			custom,
			successMessage: ToastMessage.audienceCreated,
			errorMessage: ToastMessage.errorCreatingAudience,
		},
	};
}

export const getAudienceTypes = createFetchTypes(`${mainType}_GET_AUDIENCE`);

function getAudience(FilterOptions: FilterOptions = null) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getAudienceTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/audience${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

export const updateAudienceTypes = createFetchTypes(
	`${mainType}_UPDATE_AUDIENCE`,
);

type updateAudienceObjectType = {
	id: string;
	name: string;
	filter: string;
};

function updateAudience(
	updateAudienceObject: updateAudienceObjectType,
	custom?: 'updateAudience',
) {
	return {
		type: updateAudienceTypes,
		callAPI: () => axios.put(`${apiUrl}/audience`, updateAudienceObject),
		payload: {
			custom: custom,
		},
	};
}

export const deleteAudienceTypes = createFetchTypes(
	`${mainType}_DELETE_AUDIENCE`,
);

function deleteAudience(id: string) {
	return {
		type: deleteAudienceTypes,
		callAPI: () => axios.delete(`${apiUrl}/audience/${id}`),
		payload: {
			successMessage: ToastMessage.audienceDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export const setAudienceBuildingCountType =
	'audience/SET_AUDIENCE_BUILDING_COUNT';

//We need this to deactivate the header of the campaign wizard if the audience hits more then the max possible
function setAudienceBuildingCount(payload: number) {
	return {
		type: setAudienceBuildingCountType,
		payload,
	};
}

export const getAudienceOptionsTypes = createFetchTypes(
	`${mainType}_GET_OPTIONS`,
);

function getAudienceOptions(): fetchAction {
	return {
		type: getAudienceOptionsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/audience?filter=${JSON.stringify(AudienceDropdownFilter)}`,
			),
	};
}

export const getAudienceByIdTypes = createFetchTypes(
	`${mainType}_GET_AUDIENCE_BY_ID`,
);

function getAudienceById(
	audienceId: string,
	customType: 'getAudience',
): fetchAction {
	return {
		type: getAudienceByIdTypes,
		callAPI: () => axios.get(`${apiUrl}/audience/${audienceId}`),
		payload: {
			custom: customType,
			successMessage: '',
			errorMessage: ToastMessage.errorCreatingAudience,
		},
	};
}

export {
	saveAudience,
	getAudience,
	updateAudience,
	deleteAudience,
	setAudienceBuildingCount,
	getAudienceOptions,
	getAudienceById,
};
