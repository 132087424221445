type Option = {
	value: any;
	label: string;
};
type ObjectToOptionsReturnType = Option[];

export function denormalizedObjectToOptions(
	objArray: object[],
	field: string,
	text?: string,
): ObjectToOptionsReturnType {
	return objArray.map((obj: { [key: string]: any }) => ({
		value: obj[field],
		label: text,
	}));
}

function ObjectToOptions(
	NormalizedObject: { [key: string]: any },
	field: string,
	text?: string,
	textFormat?: (value: string | boolean) => string,
): ObjectToOptionsReturnType {
	return (
		NormalizedObject &&
		Object.entries(NormalizedObject)?.map(([id, value]) => ({
			value: value[field],
			label: textFormat
				? textFormat(text ? value[text] || value[field] : value[field])
				: value[text] || field,
		}))
	);
}

export default ObjectToOptions;
