import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import { filterTransactionTypes, exportTransactionTypes } from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import fileDownload from 'js-file-download';
const transaction = entityNormalizer('transactions');
const pagination = { transactions: [transaction] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	transactions: {},
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
};

export type transactionsStateType = typeof initialState;

function paginationMapper(
	state: transactionsStateType,
	action: ResponseAction,
) {
	const {
		response: {
			transactions,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ transactions, page, pageSize, totalPages, count },
		pagination,
	);

	state.transactions = {
		...state.transactions,
		...normalizedData.entities.transactions,
	};
	state.pagination.pages[action.response.page] =
		normalizedData.result.transactions;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
}

export const exportTransactionsSuccessMapper = (
	state: transactionsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	fileDownload(response, 'transactions.csv');
	state.type = action.type;
};

const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(filterTransactionTypes, paginationMapper),
		...createFetchReducer(
			exportTransactionTypes,
			exportTransactionsSuccessMapper,
		),
	},
});

export default transactionSlice.reducer;
