import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	filterRepliesTypes,
	getSingleReplyTypes,
	setReplyStateTypes,
	bulkUpdateRepliesTypes,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import { replyStates } from './constants';

const reply = entityNormalizer('replies', {}, { excludeTime: true });
const video = entityNormalizer('videos', {}, { excludeTime: true });

const pagination = { replies: [reply], videos: [video] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	replies: {},
	repliesCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},

	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		reload: false,
	},
};

export type repliesStateType = typeof initialState;

function defaultRepliesPaginationMapper(
	state: repliesStateType,
	action: ResponseAction,
	cb: (replies: any, state: repliesStateType) => void,
) {
	const {
		response: {
			replies,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const normalizedData = normalize(
		{ replies, page, pageSize, totalPages, count },
		pagination,
	);
	state.pagination.pages[action.response.page] = normalizedData.result.replies;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
	state.repliesCount = action.response.count;
	cb(normalizedData.entities.replies, state);
}

function paginationMapper(replies: any, state: repliesStateType) {
	state.replies = { ...replies };
}

function filterMapper(replies: any, state: repliesStateType) {
	if (state.pagination.currentPage === 1) {
		return paginationMapper(replies, state);
	}
	state.custom.reload = false;
	state.replies = { ...state.replies, ...replies };
}

function getSingleReplyMapper(state: repliesStateType, action: ResponseAction) {
	const newReply = action.response.reply;
	const replyId = newReply._id;

	if (newReply?.storyteller && !newReply.storyteller.fullName) {
		newReply.storyteller.fullName = `${newReply.storyteller.firstName} ${newReply.assignedTo.lastName}`;
	}

	const normalizedData = normalize(
		{ replies: { ...state.replies, [replyId]: action.response.reply } },
		pagination,
	);
	state.replies = { ...normalizedData.entities.replies };
}

function setReplyStateMapper(state: repliesStateType, action: ResponseAction) {
	const replyId = action.response.reply._id;
	const newState = action.response.reply.state;
	const normalizedData = normalize(
		{
			replies: {
				...state.replies,
				[replyId]: {
					...state.replies[replyId],
					state: newState,
				},
			},
		},
		pagination,
	);

	// If it changed to other state different than seen then it shouldn't be displayed as a reply
	if (newState !== replyStates.seen) {
		delete normalizedData.entities.replies[replyId];
	}
	state.replies = { ...normalizedData.entities.replies };
}

function bulkUpdateRepliesMapper(
	state: repliesStateType,
	action: ResponseAction,
) {
	action.response.replyIds.forEach((id: string) => {
		delete state.replies[id];
	});

	const normalizedData = normalize(
		{
			replies: {
				...state.replies,
			},
		},
		pagination,
	);

	// If it changed to other state different than seen then it shouldn't be displayed as a reply
	state.replies = { ...normalizedData.entities.replies };
	state.custom.reload = true;
}

const replySlice = createSlice({
	name: 'reply',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(
			filterRepliesTypes,
			(state: repliesStateType, action) =>
				defaultRepliesPaginationMapper(state, action, filterMapper),
		),
		...createFetchReducer(getSingleReplyTypes, getSingleReplyMapper),
		...createFetchReducer(setReplyStateTypes, setReplyStateMapper),
		...createFetchReducer(bulkUpdateRepliesTypes, bulkUpdateRepliesMapper),
	},
});

export default replySlice.reducer;
