import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl, ToastMessage } from '../../constants';
import { fetchAction } from '../types';
import { FilterOptions } from '../../types/index';
import axios from '../../../global/axios';
import { EditStoryOptionsTypes, SendStoryOptionsTypes } from '../stories/types';
import { storyStatus } from '../stories/constants';
import { CaptionType } from '../../../common/components/interaction/CaptionEditor/types';

const mainType = 'scheduled';

export const getScheduledTypes = createFetchTypes(`${mainType}_GET_SCHEDULED`);

export const editStoriesTypes = createFetchTypes(`${mainType}_EDIT_STORIES`);

export const sendStoriesTypes = createFetchTypes(`${mainType}_SEND_STORIES`);

export const sendNowTypes = createFetchTypes(`${mainType}_SEND_NOW`);

export const bulkDeleteTypes = createFetchTypes(`${mainType}_BULK_DELETE`);
export const getSingleStoryTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_STORY`,
);

function getSingleStory(storyId: string, FilterOptions: FilterOptions) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getSingleStoryTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories/${storyId}${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			errorMessage: ToastMessage.errorStoryNotFound,
		},
	};
}

//stays
// ### get scheduled here , we can clean this and not pass an object, just pagination to it , as filters are fixed
function getScheduled(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify({
		...FilterOptions,
		filter: [
			{ field: 'status', value: storyStatus.scheduled },
			...FilterOptions.filter,
		],
	});

	return {
		type: getScheduledTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/stories${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			custom: 'getScheduled',
		},
	};
}

function sendStory(SendOptions: SendStoryOptionsTypes): fetchAction {
	return {
		type: sendStoriesTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/stories`, {
				...SendOptions,
			}),
	};
}

function sendNow(stories: string[]): fetchAction {
	return {
		type: sendNowTypes,
		callAPI: () => axios.post(`${apiUrl}/stories/send`, { stories }),
		payload: {
			custom: 'sendNow',
			stories,
		},
	};
}

function editStories(EditOptions: EditStoryOptionsTypes): fetchAction {
	return {
		type: editStoriesTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/stories/bulk/edit`, {
				...EditOptions,
			}),
		payload: {
			custom: 'editStories',
			successMessage: ToastMessage.editedAssignedStory,
			errorMessage: ToastMessage.error,
		},
	};
}

function bulkDelete(storiesId: string[]) {
	return {
		type: bulkDeleteTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/stories/bulk`, { data: { storiesId } }),
		payload: {
			custom: 'bulkDelete',
			successMessage:
				storiesId.length > 1
					? ToastMessage.severalScheduledStoriesDeleted
					: ToastMessage.singleScheduledStoryDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export const updateScheduledVideoCaptionsTypes = `${mainType}/UPDATE_VIDEO_CAPTIONS`;

function updateScheduledVideoCaptions(
	scheduledVideoId: string,
	captions: CaptionType[],
) {
	return {
		type: updateScheduledVideoCaptionsTypes,
		payload: {
			scheduledVideoId,
			captions,
		},
	};
}

export const updateScheduledVideoFieldTypes = `${mainType}/UPDATE_VIDEO_FIELD`;

function updateScheduledVideoField(
	scheduledId: string,
	field: string,
	value: any,
) {
	return {
		type: updateScheduledVideoFieldTypes,
		payload: {
			scheduledId,
			field,
			value,
		},
	};
}

export {
	getScheduled,
	editStories,
	sendStory,
	sendNow,
	bulkDelete,
	getSingleStory,
	updateScheduledVideoCaptions,
	updateScheduledVideoField,
};
