import { VideoPageSettingsType } from '../../types';

export enum CampaignStateEnum {
	active = 'active',
	inactive = 'inactive',
	draft = 'draft',
}

export type campaignStatusType =
	| CampaignStateEnum.active
	| CampaignStateEnum.inactive
	| CampaignStateEnum.draft;

export enum SyncStatus {
	READY = 'ready',
	INACTIVE = 'inactive',
	IMPORTING = 'importing',
}

export type StatsType = {
	playPercentage: number;
	engagementRate: number;
	numberOfPlays: number;
	uniquePlays: number;
	storyViews: number;
	uniqueStoryViews: number;
	shares: number;
	shareViews: number;
	shareSignUps: number;
	totalTransactedSum: number;
	numberOfTransactions: number;
	abandonedTransactions: number;
	numberOfLinkClicks: number;
	storiesSent: number;
};

export type CampaignSettingsType = VideoPageSettingsType;

export type CampaignType = {
	value: any;
	hideReply?: boolean;
	tags: any[];
	contacts: any[];
	storytellers: any[];
	completedStories: any[];
	stats: StatsType;
	callToAction: any;
	audience: any;
	state: campaignStatusType;
	creationMethod: string;
	expirationMethod: string;
	personalization: string;
	pendingStories: any[];
	assignedStories: any[];
	storiesPendingApproval: any[];
	videosRequiringApproval: number;
	storiesCount: number;
	instructions?: string;
	noInsights: number;
	waitForProcessing: number;
	strictApproval: number;
	heartCount: number;
	sendThroughDefault: boolean;
	childCampaigns: any[];
	isChild: boolean;
	smsSent: boolean;
	unsubscribes: number;
	bounces: number;
	emailPreferenceCount: number;
	SMSPreferenceCount: number;
	bothPreferenceCount: number;
	isComplete: boolean;
	scheduledStories: any[];
	selectedContactLists: any[];
	scheduledByWindow: boolean;
	_id: string;
	name: string;
	location: string;
	scheduleDate: string | Date;
	smsBody?: string;
	emailSubjectTitle?: string;
	recipe?: any;
	workspace: string;
	lastUpdatedBy: string;
	createdBy: Date | string;
	triggers: any[];
	createdAt: string;
	updatedAt: string;
	currentChild: null | any[];
	currentStep: string;
	filter: null | string;
	video: any;
	template: null | any;
	launchedAt: string | Date;
	emailsSent: number;
	lastStorySentAt: string;
	lastStorySentUrl: string;
	emailOpens: number;
	engagementCount: number;
	lastStoryResentAt: string;
	contactCount: number;
	assignedStoriesCount: number;
	completedStoriesCount: number;
	pendingStoriesCount: number;
	storiesPendingApprovalCount: number;
	id: string;
	formattedUpdatedAt: string;
	formattedCreatedAt: string;
	recordingScript: string;
	autoplay: boolean;
	settings?: CampaignSettingsType;
	syncStatus?: SyncStatus;
	lastSyncDate?: string | Date;
};
