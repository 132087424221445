import {
	configureStore,
	getDefaultMiddleware,
	combineReducers,
} from '@reduxjs/toolkit';

import contact from './contact';
import campaign from './campaign';
import recipe from './recipe';
import cta from './cta';
import story from './stories';
import approval from './approval';
import assigned from './assigned';
import reply from './reply';
import scheduled from './scheduled';
import sent from './sent';
import storyteller from './storyteller';
import storyTemplate from './storyTemplate';
import blackbaud from './blackbaud';
import sharedVideo from './sharedVideo';
import video from './video';
import tag from './tag';
import transaction from './transaction';
import workspace from './workspace';
import auth from './auth';
import onboarding from './onboarding';
import audience from './audience';
import automation from './automations';
import fetchMiddleware from './fetch-middleware';
import { useAppDispatch } from './types';

export const storeReducers = combineReducers({
	auth,
	contact,
	campaign,
	recipe,
	cta,
	story,
	approval,
	assigned,
	reply,
	scheduled,
	sent,
	storyteller,
	storyTemplate,
	workspace,
	blackbaud,
	sharedVideo,
	video,
	tag,
	transaction,
	onboarding,
	audience,
	automation,
});
// const customizedMiddleware = getDefaultMiddleware({
// 	serializableCheck: false,
// 	immutableCheck: false
// });

const CLEAR_ALL_EXCEPT_AUTH = 'CLEAR_ALL_EXCEPT_AUTH';

const rootReducer = (state: any, action: any) => {
	if (action.type === CLEAR_ALL_EXCEPT_AUTH) {
		const copyAuthState = state.auth;
		state = {};
		state.auth = copyAuthState;
	}
	return storeReducers(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}),
		fetchMiddleware,
	],
});

export default store;
export { useAppDispatch };

export const clearAllExceptAuth = () => {
	return {
		type: CLEAR_ALL_EXCEPT_AUTH,
	};
};
