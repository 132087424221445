import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import { filterRecipesTypes } from './action';
import entityNormalizer from '../../utils/entityNormalizer';

const recipe = entityNormalizer('recipes');
const pagination = { recipes: [recipe] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: false,
	recipes: {},
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	recipeToEdit: null,
	error: {},
	response: {
		status: null,
		message: null,
	},
};

export type recipesStateType = typeof initialState;

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			recipes,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ recipes, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function paginationMapper(state: recipesStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);

	//Options for the dropdown menu

	state.recipes = {
		// ...state.recipes,
		...normalizedData.entities.recipes,
	};
	state.recipesCount = Object.keys(state.recipes || {})?.length || 0;
	state.pagination.pages[action.response.page] = normalizedData.result.recipes;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
}

const recipeslice = createSlice({
	name: 'recipe',
	initialState,
	reducers: {
		RESET(state: recipesStateType) {
			state = initialState;
		},
	},
	extraReducers: {
		...createFetchReducer(filterRecipesTypes, paginationMapper),
	},
});

export default recipeslice.reducer;
