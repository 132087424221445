import { createGlobalStyle } from 'styled-components';
import { GoodkindTheme } from '../global/theme';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${GoodkindTheme.colors.background} !important;
    font-family: ${GoodkindTheme.fonts.default} !important;
    color: ${GoodkindTheme.colors.dark[700]};
  }

  p {
    font-family: ${GoodkindTheme.fonts.default} !important;
  }

  a {
    text-decoration: none;
  }

  .Toastify__toast {
    height: 56px !important;
    min-height: 56px !important;
    padding: 0;
    border-radius: ${GoodkindTheme.borderRadius.default} !important;
    background-color: ${GoodkindTheme.colors.neutral[0]} !important;
    color: ${GoodkindTheme.colors.neutral[500]} !important;
    font-family: ${GoodkindTheme.fonts.default} !important;
    font-weight: 500;
  }
  
  .Toastify__toast-body {
    padding: 0 !important;

    svg {
      margin-top: -4px !important;
    }

    > div {
      > div:first-child {
        width: 36px !important; 
        height: 36px !important; 
      }
    }
  }


  .Toastify__close-button {
    padding: 8px;
    margin-left: 8px;
    
    > svg {
      fill: ${GoodkindTheme.colors.neutral[500]} !important;
    }
  }

`;
