import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl, ToastMessage } from '../../constants/index';
import { fetchAction } from '../types';
import { FilterOptions } from '../../types/index';
// import { EditStoryOptionsTypes, CreateStoryOptionsTypes } from "./types/index";
import axios from '../../../global/axios';
import { TagDropdownFilter } from '../constants';
const mainType = 'tag';

export const filterTagsTypes = createFetchTypes(`${mainType}_GET`);
export const createTagsTypes = createFetchTypes(`${mainType}_CREATE`);
export const updateTagsTypes = createFetchTypes(`${mainType}_UPDATE`);
export const deleteTagsTypes = createFetchTypes(`${mainType}_DELETE`);
export const createContactTagsTypes = createFetchTypes(
	`${mainType}_CREATE_CONTACT_TAGS`,
);

export const getTagOptionsTypes = createFetchTypes(`${mainType}_GET_OPTIONS`);

function getTagOptions(): fetchAction {
	return {
		type: getTagOptionsTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/tags?filter=${JSON.stringify(TagDropdownFilter)}`),
	};
}

function filterTags(
	FilterOptions?: FilterOptions,
	options?: { isFilter: boolean },
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterTagsTypes,
		payload: { isFilter: options?.isFilter },
		callAPI: () =>
			axios.get(
				`${apiUrl}/tags${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

function updateTag(tagId: string, text: string): fetchAction {
	return {
		type: updateTagsTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/tags/bulk`, {
				tagIds: [tagId],
				data: {
					text,
				},
			}),
		payload: {
			tagId,
			text,
			successMessage: ToastMessage.tagsUpdated,
			errorMessage: ToastMessage.error,
			custom: 'updateTag',
		},
	};
}

function deleteTag(tagId: string): fetchAction {
	return {
		type: deleteTagsTypes,
		callAPI: () => axios.delete(`${apiUrl}/tags/${tagId}`),
		payload: {
			tagId,
			successMessage: ToastMessage.tagsUpdated,
			errorMessage: ToastMessage.error,
			custom: 'deleteTag',
		},
	};
}

function createTags(tagsText: string[]): fetchAction {
	return {
		type: createTagsTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/tags/bulk`, {
				tagsText,
			}),
		payload: {
			custom: 'createTags',
		},
	};
}
function createContactTags(actionPayload: {
	noSuccessToast?: boolean;
	contacts: string[];
	tags: string[];
}): fetchAction {
	return {
		type: createContactTagsTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/tags/addToContacts`, {
				contacts: actionPayload.contacts,
				tags: actionPayload.tags,
			}),
		payload: {
			successMessage: actionPayload.noSuccessToast
				? null
				: ToastMessage.tagsCreated,
			errorMessage: ToastMessage.error,
		},
	};
}

export {
	filterTags,
	updateTag,
	deleteTag,
	getTagOptions,
	createTags,
	createContactTags,
};
