import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction, clientAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { grantAccessTypes, verifyTypes } from './action';
import TokenStorage from '../../utils/TokenStorage';
import { authWorkspaceLoginTypes } from '../onboarding/actions';
import authConfig from '../../authConfig';

const initialState: GenericEntityState = {
	loading: true,
	identityToken: null,
	accessToken: null,
	error: {},
	response: {
		status: null,
		message: null,
	},
};

export type AuthState = typeof initialState;

function storeIdentity(state: AuthState, action: ResponseAction) {
	const {
		response: { identityToken, storyteller },
	} = action;
	TokenStorage.setIdentity(identityToken);
	state.identityToken = identityToken;
	state.user = storyteller;
	if (action.payload?.reload && !!identityToken) {
		window.location.reload();
	}
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		SET_AUTH_CLIENT(state: AuthState, action: clientAction) {
			state.authClient = action.client;
		},
		CLEAR_AUTH(state: AuthState, action: clientAction) {
			// state = initialState;
			try {
				state?.authClient?.logout({ returnTo: authConfig.logoutUrl });
			} catch (e) {}
		},
	},
	extraReducers: {
		...createFetchReducer(grantAccessTypes, storeIdentity),
		...createFetchReducer(verifyTypes),
		...createFetchReducer(authWorkspaceLoginTypes, storeIdentity),
	},
});

export default authSlice.reducer;

export const { CLEAR_AUTH } = authSlice.actions;
