import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl } from '../../constants/index';
import { fetchAction, GenericAPIResponse } from '../types';
import { FilterOptions } from '../../types/index';

import axios from '../../../global/axios';
import { CTADropdownFilter } from '../constants';
const mainType = 'cta';
type cta = {
	[key: string]: {};
};

type ResponseType = GenericAPIResponse & {
	ctas: cta[];
};

export const filterCtaTypes = createFetchTypes(`${mainType}_FILTER_CTA`);
export const deleteCtaTypes = createFetchTypes(`${mainType}_DELETE_CTA`);
export const createCtaTypes = createFetchTypes(`${mainType}_CREATE_CTA`);
export const updateCtaTypes = createFetchTypes(`${mainType}_UPDATE_CTA`);
export const softBulkTypes = createFetchTypes(`${mainType}_SOFT_BULK_CTA`);

export const getCtaOptionsTypes = createFetchTypes(`${mainType}_GET_OPTIONS`);

function getCtaOptions(options?: { excludeAll?: boolean }): fetchAction {
	return {
		type: getCtaOptionsTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/cta?filter=${JSON.stringify(CTADropdownFilter)}`),
		payload: {
			excludeAll: options.excludeAll,
		},
	};
}

function filterCtas(filterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: filterCtaTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/cta${filterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

function deleteCtas(DeleteOptions: string[]): fetchAction {
	const deleteString = JSON.stringify(DeleteOptions);
	return {
		type: deleteCtaTypes,
		callAPI: () =>
			axios.delete(
				`${apiUrl}/cta${DeleteOptions ? `?filter=${deleteString}` : ``}`,
			),
	};
}

function createCta(
	CreateOptions: FormData,
	custom: string = null,
): fetchAction {
	return {
		type: createCtaTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/cta`, CreateOptions, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		payload: {
			...(custom ? { custom } : {}),
		},
	};
}

function updateCta(form: FormData, id: string): fetchAction {
	return {
		type: updateCtaTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/cta/${id}`, form, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
	};
}

function softBulk(ctaIds: string[]) {
	return {
		type: softBulkTypes,
		callAPI: () =>
			axios.delete(`${apiUrl}/cta/soft/bulk`, {
				data: { ctaIds: ctaIds },
			}),
		payload: { ctaIds: ctaIds },
	};
}

export {
	filterCtas,
	deleteCtas,
	createCta,
	updateCta,
	softBulk,
	getCtaOptions,
};
