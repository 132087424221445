import { apiUrl } from '../../constants';
import axios from '../../../global/axios';
import { SyncStorytellersObject } from './types';
import createFetchTypes from '../../utils/createFetchTypes';
import { OnBoardingFormType } from '../../containers/OnBoarding/types';

const mainType = 'onboarding';

export const sendStorytellersTypes = createFetchTypes(
	`${mainType}_SEND_STORYTELLERS`,
);

export const authWorkspaceLoginTypes = createFetchTypes(
	`${mainType}_AUTH_WORKSPACE_LOGIN`,
);

function sendStorytellers(SendStorytellers: SyncStorytellersObject) {
	return {
		type: sendStorytellersTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers/send`, SendStorytellers),
	};
}

function authWorkspaceLogin(OnBoardingForm: OnBoardingFormType) {
	return {
		type: authWorkspaceLoginTypes,
		callAPI: () =>
			axios.post(
				`${apiUrl}/auth/workspaceAndLogin?createStory="true"`,
				OnBoardingForm,
			),
	};
}

export { sendStorytellers, authWorkspaceLogin };
