import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl, ToastMessage, operators, orderTypes } from '../../constants';

import { fetchAction, GenericAPIResponse } from '../types';
import { FilterOptions } from '../../types/index';
import axios from '../../../global/axios';
import { importStoryteller } from './types';
import { StorytellerDropdownFilter } from '../constants';
const mainType = 'storyteller';
type storyteller = {
	[key: string]: any;
};

type ResponseType = GenericAPIResponse & {
	Storytellers: storyteller[];
};

export const importStorytellersTypes = createFetchTypes(`${mainType}_IMPORT`);

export const verifyStorytellerTypes = createFetchTypes(`${mainType}_VERIFY`);
export const resendStorytellerVerificationTypes = createFetchTypes(
	`${mainType}_RESEND`,
);

export const getStorytellerOptionsTypes = createFetchTypes(
	`${mainType}_GET_OPTIONS`,
);

function getStorytellerOptions(): fetchAction {
	return {
		type: getStorytellerOptionsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/storytellers?filter=${JSON.stringify(
					StorytellerDropdownFilter,
				)}`,
			),
	};
}

function importStorytellers(data: importStoryteller): fetchAction {
	return {
		type: importStorytellersTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers/import`, data),
		payload: {
			custom: 'importStorytellers',
		},
	};
}

export const exportStorytellersTypes = createFetchTypes(`${mainType}_EXPORT`);

function exportStorytellers(filterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: exportStorytellersTypes,
		callAPI: () =>
			axios({
				url: `${apiUrl}/storytellers/export${
					filterOptions ? `?filter=${filterString}` : ``
				}`,
				method: 'GET',
				responseType: 'blob',
			}),
		payload: {
			custom: 'exportStorytellers',
		},
	};
}

export const filterStorytellersTypes = createFetchTypes(`${mainType}_FILTER`);

function filterStorytellers(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterStorytellersTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/storytellers${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
	};
}

export const filterStorytellerPendingInvitesTypes = createFetchTypes(
	`${mainType}_FILTER_PENDING_INVITES`,
);

function filterStorytellerPendingInvites(search?: string): fetchAction {
	const filterObject = [
		{
			field: 'lastLoginAt',
			operator: operators.exists,
			value: false,
		},
		{
			field: 'role',
			value: 'recorder',
		},
	];

	const paginationObject = {
		page: 1,
		pageSize: 100,
	};

	const orderObject = { field: 'createdAt', type: orderTypes.descending };

	const searchObject = {
		fields: ['firstName', 'lastName'],
		value: search,
	};

	const filterOptions = {
		pagination: paginationObject,
		filter: filterObject,
		order: orderObject,
		search: searchObject,
	};

	const filterString = JSON.stringify(filterOptions);
	return {
		type: filterStorytellerPendingInvitesTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
	};
}

export const filterAdminsTypes = createFetchTypes(`${mainType}_FILTER_ADMINS`);

function filterAdmins(FilterOptions: FilterOptions): fetchAction {
	const { filter } = FilterOptions;

	if (filter) {
		FilterOptions.filter.push({ field: 'role', value: 'admin' });
	} else {
		FilterOptions.filter = [{ field: 'role', value: 'admin' }];
	}

	const filterString = JSON.stringify(FilterOptions);

	return {
		type: filterAdminsTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
		payload: {
			custom: 'admins',
		},
	};
}

export const filterAdminPendingInvitesTypes = createFetchTypes(
	`${mainType}_FILTER_ADMINS_PENDING_INVITES`,
);

function filterAdminPendingInvites(search?: string): fetchAction {
	const filterObject = [
		{
			field: 'lastLoginAt',
			operator: operators.exists,
			value: false,
		},
		{
			field: 'role',
			value: 'admin',
		},
	];

	const searchObject = {
		fields: ['firstName', 'lastName'],
		value: search,
	};

	const paginationObject = {
		page: 1,
		pageSize: 100,
	};

	const orderObject = { field: 'createdAt', type: orderTypes.descending };

	const filterOptions = {
		pagination: paginationObject,
		filter: filterObject,
		order: orderObject,
		search: searchObject,
	};

	const filterString = JSON.stringify(filterOptions);
	return {
		type: filterAdminPendingInvitesTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers?filter=${filterString}`),
		payload: {
			custom: 'admins',
		},
	};
}

export const retrieveSavedAdminsTypes = createFetchTypes(
	`${mainType}_RETRIEVE_SAVED_ADMINS`,
);

function retrieveSavedAdmins(admins: string[]) {
	const filter = {
		filter: [
			{
				field: '_id',
				value: admins,
			},
		],
	};
	return {
		type: filterStorytellersTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/storytellers?filter=${JSON.stringify(filter)}`),
		payload: {
			custom: 'retrieveSavedAdmins',
		},
	};
}

export const createStorytellerTypes = createFetchTypes(`${mainType}_CREATE`);

function createStoryteller(
	data: { [key: string]: any },
	custom?: string,
): fetchAction {
	return {
		type: createStorytellerTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers`, data),
		payload: {
			custom,
			successMessage: ToastMessage.storytellerCreated,
			errorMessage: ToastMessage.errorCreatingStoryteller,
		},
	};
}

export const updateStorytellerTypes = createFetchTypes(`${mainType}_UPDATE`);

function updateStoryteller(data: { [key: string]: any }): fetchAction {
	return {
		type: updateStorytellerTypes,
		callAPI: () =>
			axios.put(`${apiUrl}/storytellers`, { id: data.id, storyteller: data }),
		payload: {
			successMessage: ToastMessage.storytellerUpdated,
			errorMessage: ToastMessage.errorEditingStoryteller,
		},
	};
}

function verifyStoryteller(id: string): fetchAction {
	return {
		type: verifyStorytellerTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/${id}/verifySender`),
		payload: {
			custom: 'verification',
		},
	};
}

function resendStorytellerVerification(id: string): fetchAction {
	return {
		type: resendStorytellerVerificationTypes,
		callAPI: () => axios.get(`${apiUrl}/storytellers/${id}/resendVerification`),
		payload: {
			custom: 'verification',
		},
	};
}

export const resetStoryTellerType = `${mainType}/RESET`;

export function resetStoryteller() {
	return {
		type: resetStoryTellerType,
	};
}

export const manuallyAddStoryteller = `${mainType}/MANUALLY_ADD_STORYTELLER`;

export function manualyAddStoryteller(storyteller: any) {
	return {
		type: manuallyAddStoryteller,
		payload: storyteller,
	};
}

export const sendSmsAppLinkTypes = createFetchTypes(
	`${mainType}_SEND_SMS_APPLINK`,
);

function sendSmsAppLink(payload: { number: string }): fetchAction {
	return {
		type: sendSmsAppLinkTypes,
		callAPI: () => axios.post(`${apiUrl}/storytellers/smsAppLinks`, payload),
	};
}

export const uploadStorytellerProfilePictureType = createFetchTypes(
	`${mainType}_UPLOAD_PROFILE_PICTURE`,
);

function uploadStorytellerProfilePicture(profilePictureForm: FormData) {
	return {
		type: uploadStorytellerProfilePictureType,
		callAPI: () =>
			axios.post(
				`${apiUrl}/storytellers/uploadProfilePicture`,
				profilePictureForm,
			),
	};
}

export const clearStorytellersType = 'storyteller/CLEAR_STORYTELLERS';

function clearStorytellers() {
	return {
		type: clearStorytellersType,
	};
}

export const bulkSoftDeleteStorytellersType = createFetchTypes(
	`${mainType}/DELETE_STORYTELLERS`,
);

function bulkSoftDeleteStorytellers(storytellerIds: string[]) {
	return {
		type: bulkSoftDeleteStorytellersType,
		callAPI: () =>
			axios.delete(`${apiUrl}/storytellers/soft/bulk`, {
				data: { storytellerIds: storytellerIds },
			}),
		payload: {
			storytellerIds,
			successMessage: ToastMessage.storytellerDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export {
	filterStorytellers,
	filterStorytellerPendingInvites,
	filterAdmins,
	filterAdminPendingInvites,
	createStoryteller,
	updateStoryteller,
	importStorytellers,
	verifyStoryteller,
	resendStorytellerVerification,
	exportStorytellers,
	sendSmsAppLink,
	uploadStorytellerProfilePicture,
	getStorytellerOptions,
	clearStorytellers,
	retrieveSavedAdmins,
	bulkSoftDeleteStorytellers,
};
