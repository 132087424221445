import { pagination } from './constants';
import { normalize } from 'normalizr';
import { ResponseAction } from '../types';
import { contactsStateType } from './@types';
import fileDownload from 'js-file-download';
export const paginationSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: {
			contacts,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ contacts, page, pageSize, totalPages, count },
		pagination,
	);

	state.contacts = {
		...(page > 1 ? { ...state.contacts } : {}),
		...normalizedData.entities.contacts,
	};
	state.contactsCount = Object.keys(state.contacts)?.length;
	state.pagination.pages[action.response.page] = normalizedData.result.contacts;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
};

export const complexSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: {
			contacts,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
		payload: { audienceId },
	} = action;
	const normalizedData = normalize(
		{ contacts, page, pageSize, totalPages, count },
		pagination,
	);

	const copyContacts = action.response.page > 1 ? { ...state.contacts } : {};
	if (action.payload?.custom) {
		state.custom.contacts = {
			...copyContacts,
			...normalizedData.entities.contacts,
		};
		state.custom.pagination = {
			pages: {},
			currentPage: action.response.page,
			totalPages: action.response.totalPages,
			count: action.response.count || 0,
		};
		state.custom.pagination.pages[action.response.page] =
			normalizedData.result.contacts;
	} else {
		if (audienceId) {
			state.response.audienceId = audienceId;
		}
		state.contacts = { ...copyContacts, ...normalizedData.entities.contacts };
		state.contactsCount = Object.keys(state.contacts)?.length;
		state.pagination.currentPage = action.response.page;
		state.pagination.totalPages = action.response.totalPages;
		state.pagination.count = action.response.count;
	}
};

export const uploadFlatfileContactsSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: { contacts },
	} = action;
	//const normalizedData = normalize({ contacts }, pagination);
	const { response } = action;
	// state.response.message = "contact import successful"
	state.type = action.type;
};

export const softDeleteContactsSuccessMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	const {
		response: { contacts },
		payload,
	} = action;
	const result = payload.contactIds.reduce(
		(objToReturn: any, id: string) => {
			const { [id]: value, ...rest } = objToReturn;
			return { ...rest };
		},
		{ ...state.contacts },
	);
	state.contacts = result;
	state.update = !state.update;
};

export const uploadFlatfileContactsErrorMapper = function paginationMapper(
	state: contactsStateType,
	action: ResponseAction,
): void {
	state.type = action.type;
};

export const updateContactsSuccessMapper = function (
	state: contactsStateType,
	action: ResponseAction,
) {
	const { payload, response } = action;
	const auxContact = state.contacts[payload.contactId];
	const tagIndex = auxContact.tags.findIndex(
		(tag: any) => tag.id === payload.tagId,
	);
	const newTags = auxContact.tags;
	newTags[tagIndex] = { ...response.tag, edited: true };

	const result = {
		...state.contacts,
		[payload.contactId]: {
			...auxContact,
			tags: newTags,
		},
	};

	state.contacts = result;
	state.update = !state.update;
};

export const deleteContactsTagSucessMaper = function (
	state: contactsStateType,
	action: ResponseAction,
) {
	const { payload } = action;
	const auxContact = state.contacts[payload.contactId];
	const tagIndex = auxContact.tags.findIndex(
		(tag: any) => tag.id === payload.tagId,
	);
	const newTags = auxContact.tags;
	newTags[tagIndex] = { ...newTags[tagIndex], deleted: true };

	const result = {
		...state.contacts,
		[payload.contactId]: {
			...auxContact,
			tags: newTags,
		},
	};

	state.contacts = result;
	state.update = !state.update;
};

export const exportContactsSuccessMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	const { response } = action;
	fileDownload(response, 'contacts.csv');
	state.type = action.type;
};

export const repliesActivityMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	if (action.response.page === 1) {
		state.repliesActivity = action.response.replies;
	} else {
		state.repliesActivity = [
			...state.repliesActivity,
			...action.response.replies,
		];
	}

	state.repliesActivityPagination.currentPage = action.response.page;
	state.repliesActivityPagination.totalPages = action.response.totalPages;
	state.repliesActivityPagination.count = action.response.count;
};

export const storiesActivityMapper = (
	state: contactsStateType,
	action: ResponseAction,
) => {
	if (action.response.page === 1) {
		state.storiesActivity = action.response.stories;
	} else {
		state.storiesActivity = [
			...state.storiesActivity,
			...action.response.stories,
		];
	}

	state.storiesActivityPagination.currentPage = action.response.page;
	state.storiesActivityPagination.totalPages = action.response.totalPages;
	state.storiesActivityPagination.count = action.response.count;
};
