import React from 'react';

import * as ReactDOM from 'react-dom';
import App from './app';
const ROOT = document.querySelector('.container');
ReactDOM.render(<App />, ROOT);

if ((module as any).hot) {
	(module as any).hot.accept('./app', function () {
		ReactDOM.render(<App />, ROOT);
	});
}
