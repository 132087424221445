import {
	lighten as lightenColor,
	darken as darkenColor,
} from '@material-ui/core';

import { ColorCoefficientType } from './types';

const paletteColors = {
	neutral: '#050A31',
	dark: '#131A54',
	primary: '#0074E8',
	background: '#F6F7FA',
	white: '#FFF',
	alert: '#F45AC1',
	warning: '#FA9B1E',
	success: '#53BBC3',
	accent: {
		green: '#53DDE8',
		yellow: '#FFDC2C',
		orange: '#FA9B1E',
		pink: '#F45AC1',
		purple: '#8C4FFF',
	},
};

const lighten = {
	primary: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.primary, 1 - coefficient),

	neutral: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.neutral, 1 - coefficient),

	dark: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.dark, 1 - coefficient),

	alert: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.alert, 1 - coefficient),

	warning: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.warning, 1 - coefficient),

	success: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.success, 1 - coefficient),

	green: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.accent.green, 1 - coefficient),

	pink: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.accent.pink, 1 - coefficient),

	yellow: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.accent.yellow, 1 - coefficient),

	orange: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.accent.orange, 1 - coefficient),

	purple: (coefficient: ColorCoefficientType) =>
		lightenColor(paletteColors.accent.purple, 1 - coefficient),
};

const darken = {
	primary: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.primary, coefficient),

	neutral: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.neutral, coefficient),

	dark: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.dark, coefficient),

	alert: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.alert, coefficient),

	warning: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.warning, coefficient),

	success: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.success, coefficient),

	green: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.accent.green, coefficient),

	pink: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.accent.pink, coefficient),

	yellow: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.accent.yellow, coefficient),

	orange: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.accent.orange, coefficient),

	purple: (coefficient: ColorCoefficientType) =>
		darkenColor(paletteColors.accent.purple, coefficient),
};

const colors = {
	...paletteColors,
	label: '#303b8e',
	labelTransparent: '#303b8e7e',
	sentiment: '#F2557A',
	sentimentTransparent: '#f2547933',
	sentimentStrong: '#b92323',
	delivered: '#74A7E5',
	sent: '#74A7E5',
	'sent to carrier': '#74A7E5',
	opened: '#61CD8D',
	bounced: '#FC7283',
	'in approvals': '#FE9E5F',
	pending: '#FE9E5F',
	processed: '#9191ed',
	scheduled: '#9191ed',
	dropped: '#e46464',
	whatsGreen: '#1CC908',
	orange: '#e07a5f',
	lightBlue: '#5383f9',
	lightestBlue: '#f2fbfc',
	lightPurple: '#ede6fb',
	softRed: 'rgba(215, 97, 97, 0.12)',
	strongRed: '#dd1616',
	lightGreen: '#effff5',
	green: '#19C6C2',

	// This color scheme is used to create general elements, text or containers in the app
	neutral: {
		'0': paletteColors.white,
		'50': lighten.neutral(0.05), // #f2f2f4
		'100': lighten.neutral(0.1), // #e6e6ea
		'200': lighten.neutral(0.3), // #b4b5c1
		'300': lighten.neutral(0.4), // #9b9dac
		'400': lighten.neutral(0.6), // #696c83
		'500': lighten.neutral(0.7), // #50536e
		'700': paletteColors.neutral, // #050A31
	},

	dark: {
		'400': lighten.dark(0.6), // #717598
		'500': lighten.dark(0.7), // #595e87
		'600': lighten.dark(0.8), // #424776
		'700': paletteColors.dark, // #131a54
	},

	// This color is used scheme for interactive elements
	primary: {
		'50': lighten.primary(0.05), // #f6fafe
		'100': lighten.primary(0.1), // #edf5fd
		'200': lighten.primary(0.3), // #c9e1f9
		'300': lighten.primary(0.4), // #b7d7f8
		'400': lighten.primary(0.6), // #93c4f4
		'500': lighten.primary(0.7), // #81baf3
		'600': lighten.primary(0.8), // #6fb0f1
		'700': paletteColors.primary, // #4c9dee
		'900': darken.primary(0.3), // #356da6
	},

	// This color scheme is used for some interactive elements according to the mockups
	secondary: {
		'50': '#ffe8dd',
		'100': '#ffd8c5',
		'300': '#ffb18d',
		'500': '#ff864e',
		'700': '#ff5c10',
		'900': '#cd490b',
	},

	// This color scheme is used for success states within the app
	success: {
		'50': lighten.success(0.05), // #f6fbfc
		'100': lighten.success(0.1), // #edf8f9
		'300': lighten.success(0.4), // #bae3e7
		'500': lighten.success(0.7), // #86cfd5
		'700': paletteColors.success, // #53BBC3
		'900': darken.success(0.3), // #3a8288
	},

	// This color scheme is used for warning states within the app
	warning: {
		'50': lighten.warning(0.05), // #fefaf3
		'300': lighten.warning(0.4), // #fdd7a5
		'500': lighten.warning(0.7), // #fbb961
		'700': paletteColors.warning, // #FA9B1E
		'900': darken.warning(0.3), // #af6c15
	},

	// This color scheme is used for error states within the app
	alert: {
		'50': lighten.alert(0.05), // #fef6fb
		'300': lighten.alert(0.4), // #fabde6
		'500': lighten.alert(0.7), // #f78bd3
		'700': paletteColors.alert, // #f45ac1
		'900': darken.alert(0.3), // #aa3e87
	},

	// This color scheme is used for tags
	tags: {
		'50': lighten.yellow(0.05), // #fffdf4
		'100': lighten.yellow(0.1), // #fffbe9
		'300': lighten.yellow(0.4), // #fff1aa
		'500': lighten.yellow(0.7), // #ffe66b
		'700': paletteColors.accent.yellow, // #ffdc2c
		'900': darken.yellow(0.3), // #b29a1e
	},

	accent: {
		purple: {
			'300': lighten.purple(0.4), // #dccaff
			'700': paletteColors.accent.purple, // #8c4fff
		},

		yellow: {
			'300': lighten.yellow(0.4),
			'700': paletteColors.accent.yellow, // #FFDC2C
		},

		green: {
			'700': paletteColors.accent.green, // #53DDE8
		},
	},

	gradients: {
		primary: `linear-gradient(90deg, #ff5c10 0%, #F6C93D 100%);`,
		secondary: `linear-gradient(90deg, #00b9a5 0%, #009686 100%);`,
	},
};

const fonts = {
	default:
		'Inter, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif',
};

const fontSizes = {
	h1: '24px',
	h2: '20px',
	large: '16px',
	regular: '14px',
	small: '12px',
	smaller: '10px',
};

const border = {
	block: `1px solid ${colors.neutral[100]}`,
};

const borderRadius = {
	subtle: '4px',
	smooth: '6px',
	default: '8px',
	rounded: '12px',
	round: '16px',
	fullRounded: '50%',

	components: {
		smaller: '12px',
		small: '16px',
		regular: '20px',
		large: '24px',
	},
};

const buttonHeight = {
	smaller: '24px',
	small: '32px',
	regular: '40px',
	large: '48px',
};

const buttonFontSize = {
	smaller: '12px',
	small: '14px',
	regular: '16px',
	large: '20px',
};

const buttonPadding = {
	smaller: '0px 16px',
	small: '4px 16px',
	regular: '8px 24px',
	large: '12px 32px',
};

const iconButtonPadding = {
	small: '4px 8px',
	regular: '8px 16px',
	large: '12px 24px',
};

const boxShadow = {
	default: '0 4px 10px 0 rgba(0, 0, 0, 0.15)',
	hover: '0 3px 8px 0 rgba(0, 0, 0, 0.12)',
	bottom: '0px 3px 9px -2px rgba(0,0,0,0.12)',
	right: '3px 0 5px -2px rgba(0, 0, 0, 0.25)',
	glow: `0 0 4px 1px ${colors.neutral[200]}`,
	glowActive: `0 0 4px 2px ${colors.neutral[200]}`,
	bigCards: `0px 1px 5px ${colors.neutral[100]}`,
	selectedBigCards: `0 0 0 4px ${colors.secondary[700]}`,
	smallCards: `0px 1px 3px ${colors.neutral[100]}`,
	selectedSmallCards: `0 0 0 2px ${colors.secondary[700]}`,
	optionCard: '0 1px 4px 0 rgba(46, 60, 74, 0.16)',
	optionCardSelected: '0 2px 8px 0 rgba(46, 60, 74, 0.16)',
	wideCard: '0 2px 8px 0 rgba(46, 60, 74, 0.16)',

	light: '0 1px 4px 0 rgba(46, 60, 74, 0.16)',
	medium: '0 2px 8px 0 rgba(46, 60, 74, 0.16)',
	strong: '0 4px 16px 0 rgba(46, 60, 74, 0.16)',

	cardHover: `1px 1px 3px ${paletteColors.dark}26`,

	modal: `0px 2px 2px rgba(0, 0, 0, 0.05)`,

	options: {
		hovered: `0 0 0 1.6px ${colors.neutral[700]}, 0 0 0 2.4px ${colors.primary[50]}`,
	},
	components: {
		hovered: `0 0 0 2px ${colors.neutral[100]}`,
		focused: `0 0 0 2px ${colors.primary[100]}`,
	},
};

const transition = {
	short: '0.1s cubic-bezier(0.645, 0.045, 0.355, 1)',
	long: '0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
	oneSecond: '1s cubic-bezier(0.645, 0.045, 0.355, 1)',
	cubicBezier: 'cubic-bezier(0.645, 0.045, 0.355, 1)',

	all: 'all 0.3s ease-in-out',
	hover:
		'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, background 0.3s ease-in-out, border 0.3s ease-in-out',
	typography:
		'color 0.3s ease-in-out, transform 0.3s ease-in-out, fill 0.3s ease-in-out',
};

const inputSize = {
	height: '40px',
};

const zIndex = {
	under: -1,
	min: 1,
	mid: 5,
	max: 10,
	navBar: 20,
	overlay: 30,
};

const width = {
	page: '1280px',
};

export const GoodkindTheme = {
	name: 'Goodkind',
	colors,
	lighten,
	width,
	darken,
	border,
	fonts,
	fontSizes,
	borderRadius,
	boxShadow,
	transition,
	buttonHeight,
	buttonFontSize,
	buttonPadding,
	iconButtonPadding,
	inputSize,
	zIndex,
};

export default GoodkindTheme;
