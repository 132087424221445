import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import {
	filterCtaTypes,
	createCtaTypes,
	updateCtaTypes,
	softBulkTypes,
	getCtaOptionsTypes,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import ObjectToOptions from '../../utils/ObjectToOptions';

const cta = entityNormalizer('ctas', {}, { idAttribute: '_id' });
const pagination = { ctas: [cta] };

const initialState: GenericEntityState = {
	loading: false,
	ctas: {},
	ctasCount: 0,
	ctaOptions: [],
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type ctasStateType = typeof initialState;

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			ctas,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ ctas, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function optionsMapper(state: ctasStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	const ctaCopy = ObjectToOptions(normalizedData.entities.ctas, 'id', 'title');
	if (action.payload?.excludeAll !== true) {
		ctaCopy.unshift({
			value: '',
			label: 'All',
		});
	}
	state.ctaOptions = ctaCopy;
}

function paginationMapper(state: ctasStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	if (action.response.page === 1) {
		state.ctas = { ...normalizedData.entities.ctas };
	} else {
		state.ctas = { ...state.ctas, ...normalizedData.entities.ctas };
	}
	state.ctasCount = Object.keys(state.ctas || {})?.length;
	state.pagination.pages[action.response.page] = normalizedData.result.ctas;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
}

const ctaSlice = createSlice({
	name: 'cta',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(filterCtaTypes, paginationMapper),
		...createFetchReducer(createCtaTypes),
		...createFetchReducer(updateCtaTypes),
		...createFetchReducer(softBulkTypes),
		...createFetchReducer(getCtaOptionsTypes, optionsMapper),
	},
});

export default ctaSlice.reducer;
