import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl, ToastMessage } from '../../constants';

import axios from '../../../global/axios';
import { FilterOptions } from '../../types';

const mainType = 'storyTemplate';

export const getTemplateOptionsTypes = createFetchTypes(
	`${mainType}_FETCH_STORY_TEMPLATE_OPTIONS`,
);

export const createStoryTemplateTypes = createFetchTypes(
	`${mainType}_CREATE_STORY_TEMPLATE`,
);

export const editStoryTemplateTypes = createFetchTypes(
	`${mainType}_EDIT_STORY_TEMPLATE`,
);

export const filterTemplatesTypes = createFetchTypes(
	`${mainType}_FETCH_STORY_TEMPLATE_OPTIONS`,
);

function filterTemplates(filter: FilterOptions) {
	const filterString = JSON.stringify(filter);
	return {
		type: filterTemplatesTypes,
		callAPI: () => axios.get(`${apiUrl}/templates?filter=${filterString}`),
		payload: {
			custom: '',
			errorMessage: ToastMessage.error,
		},
	};
}

function getStoryTemplateOptions() {
	const filter = JSON.stringify({
		pagination: {
			page: 1,
			pageSize: 100,
		},
	});
	return {
		type: getTemplateOptionsTypes,
		callAPI: () => axios.get(`${apiUrl}/templates?filter=${filter}`),
		payload: {
			custom: '',
			errorMessage: ToastMessage.error,
		},
	};
}

function editStoryTemplate(id: string, params: FormData) {
	return {
		type: editStoryTemplateTypes,
		callAPI: () => axios.put(`${apiUrl}/templates/${id}`, params),
		payload: {
			custom: 'editTemplate',
			successMessage: ToastMessage.storyTemplateUpdated,
			errorMessage: ToastMessage.error,
		},
	};
}

function createStoryTemplate(params: FormData) {
	return {
		type: createStoryTemplateTypes,
		callAPI: () => axios.post(`${apiUrl}/templates`, params),
		payload: {
			custom: 'createTemplate',
			successMessage: ToastMessage.storyTemplateCreated,
		},
	};
}

function clearTemplates() {
	return {
		type: `${mainType}/CLEAR_TEMPLATES`,
	};
}

export {
	getStoryTemplateOptions,
	editStoryTemplate,
	createStoryTemplate,
	filterTemplates,
	clearTemplates,
};
