function getAuth0LocalValue() {
	try {
		const stringValue = localStorage.getItem(
			'@@auth0spajs@@::zJvWJujfu6BjSTnnegs1BkeADmcuL4uZ::default::openid profile email offline_access',
		);
		const auth0State = JSON.parse(stringValue);
		// console.log({ auth0State });
		return auth0State;
	} catch (e) {
		// console.log(e);
		return null;
	}
}

export default class TokenStorage {
	public static getAccessToken(): string {
		const token =
			getAuth0LocalValue()?.body?.access_token ||
			localStorage.getItem('accessToken');
		return token;
	}

	public static setAccessToken(accessToken: string) {
		return localStorage.setItem('accessToken', accessToken);
	}

	public static getIdentity(): string {
		return localStorage.getItem('identity');
	}

	public static setIdentity(identityToken: string) {
		return localStorage.setItem('identity', identityToken);
	}
}
