import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize, schema } from 'normalizr';
import {
	getWorkspaceByEmailTypes,
	updateWorkspaceGatewayTypes,
	WorkspaceInfoTypes,
	updateWorkspaceIntegrationTypes,
	disconnectWorkspaceIntegrationTypes,
	updateWorkspaceTypes,
	DisconnectBlackbaudGatewayTypes,
	uploadLogoTypes,
	verifyWorkspaceDomainDataTypes,
	verifyDomainTypes,
	updateWithLogoAndBackgroundtypes,
	zapierKeyTypes,
	setZapierIntegrationTypes,
	disconnectHotglueIntegrationTypes,
	updateCustomAttributesTypes,
	updateHotglueCommunicationPreferenceTypes,
	updateHotglueSyncingScheduleTypes,
	connectHotglueFlowTypes,
	getSendDataOutTargetFieldsTypes,
} from './action';
import entityNormalizer from '../../utils/entityNormalizer';
import { customAttributesSchema } from './schemas';
import { deleteAutomationTypes } from '../automations/action';

const workspace = entityNormalizer('workspaces');
const pagination = { workspaces: [workspace] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: GenericEntityState = {
	loading: true,
	workspaces: {},
	pagination: {
		pages: {},
		currentPage: null,
	},
	domainData: {},
	zapierKey: '',
	workspaceInfo: {},
	firstLogin: false,
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type workspaceStateType = typeof initialState;

function paginationMapper(state: workspaceStateType, action: ResponseAction) {
	const {
		response: { workspaces, page = null, pageSize = null, firstLogin },
	} = action;
	const normalizedData = normalize({ workspaces, page, pageSize }, pagination);

	state.workspaces = {
		...state.workspaces,
		...normalizedData.entities.workspaces,
	};
	state.firstLogin = firstLogin || false;
	// state.pagination.pages[action.response.page] = normalizedData.result;
	// state.pagination.currentPage = action.response.page;
}

function zapierGatewayMapper(state: any, action: ResponseAction) {
	const { response } = action;
	if (response && response.status == 200) {
		state.zapierKey = response.workspace.integrationId;
	}
}

function workspaceInfoMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo = response.workspace;

	const workspaceCustomAttributes = response.workspace.customAttributes;

	if (workspaceCustomAttributes?.length) {
		const customAttributeSchema = new schema.Array(customAttributesSchema);

		const normalizedCustomAttributes = normalize(
			workspaceCustomAttributes,
			customAttributeSchema,
		);

		state.normalizedCustomAttributes =
			normalizedCustomAttributes.entities.customAttributes || {};
	}

	const hasBlackbaudIntegration = response.workspace.integrations.findIndex(
		(integration: { [key: string]: any }) =>
			/blackbaud/i.test(integration.integrationName) &&
			integration.integrationOn,
	);
	const hasSalesforceIntegration = response.workspace.integrations.findIndex(
		(integration: { [key: string]: any }) =>
			integration.integrationName === 'salesforce' && integration.integrationOn,
	);
	const hasBlackbaudGateway = response.workspace.gateways.findIndex(
		(gateway: { [key: string]: any }) =>
			/blackbaud/i.test(gateway.name) && gateway.gatewayOn,
	);

	if (hasBlackbaudGateway > -1) {
		state.workspaceInfo.hasBlackbaudGateway = true;
	} else {
		state.workspaceInfo.hasBlackbaudGateway = false;
	}
	if (hasSalesforceIntegration > -1) {
		state.workspaceInfo.hasSalesforceIntegration = true;
	} else {
		state.workspaceInfo.hasSalesforceIntegration = false;
	}
	if (hasBlackbaudIntegration > -1) {
		state.workspaceInfo.hasBlackbaudIntegration = true;
	} else {
		state.workspaceInfo.hasBlackbaudIntegration = false;
	}
}

function uploadLogoMapper(state: workspaceStateType, action: ResponseAction) {
	const { response } = action;
	state.workspaceInfo.logo = response.workspace.logo;
}

function setDomainMapperSucess(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;

	state.workspaceInfo.domainVerified = response.domainVerified;
}

function verifySuccessMapper(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const { response } = action;
	Object.keys(state.domainData).map((domain) => {
		if (Object.keys(response.dnsRecords).includes(domain)) {
			state.domainData[domain].valid = response.dnsRecords[domain].valid;
		}
	});
}

function updateCustomAttributesSuccess(
	state: workspaceStateType,
	action: ResponseAction,
) {
	const {
		response: {
			workspace: { customAttributes },
		},
	} = action;
	state.workspaceInfo.customAttributes = [...customAttributes];
}

const workspaceSlice = createSlice({
	name: 'workspace',
	initialState,
	reducers: {
		RESET_WORKSPACE(state: workspaceStateType) {
			state.workspaceInfo = {};
		},
	},
	extraReducers: {
		...createFetchReducer(getWorkspaceByEmailTypes, paginationMapper),
		...createFetchReducer(
			updateWorkspaceGatewayTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(WorkspaceInfoTypes, workspaceInfoMapper),
		...createFetchReducer(connectHotglueFlowTypes, workspaceInfoMapper),

		...createFetchReducer(
			updateWorkspaceIntegrationTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			updateHotglueCommunicationPreferenceTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			updateHotglueSyncingScheduleTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			disconnectWorkspaceIntegrationTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(updateWorkspaceTypes, workspaceInfoMapper),
		...createFetchReducer(uploadLogoTypes, uploadLogoMapper),
		...createFetchReducer(
			updateWithLogoAndBackgroundtypes,
			workspaceInfoMapper,
		),
		...createFetchReducer(
			DisconnectBlackbaudGatewayTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			verifyWorkspaceDomainDataTypes,
			setDomainMapperSucess,
		),
		...createFetchReducer(verifyDomainTypes, verifySuccessMapper),
		...createFetchReducer(zapierKeyTypes, zapierGatewayMapper),
		...createFetchReducer(
			setZapierIntegrationTypes,
			(state: workspaceStateType, action: ResponseAction) => {
				workspaceInfoMapper(state, action);
			},
		),
		...createFetchReducer(
			disconnectHotglueIntegrationTypes,
			workspaceInfoMapper,
		),
		...createFetchReducer(
			updateCustomAttributesTypes,
			updateCustomAttributesSuccess,
		),
		...createFetchReducer(getSendDataOutTargetFieldsTypes),
		[deleteAutomationTypes[1]](state) {
			if (state?.custom?.disconnectHotglueResponse) {
				state.custom.disconnectHotglueResponse = null;
			}
		},
	},
});

export default workspaceSlice.reducer;
export const WorkspaceActions = workspaceSlice.actions;
