import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '../../utils/createFetchReducer';
import { ResponseAction } from '../types';
import { contactsStateType } from './@types';
import {
	filterContactsTypes,
	uploadFlatfileContactsTypes,
	initialState,
	complexFilterContactstypes,
	updateContactTagTypes,
	deleteContactTagTypes,
	softDeleteContactsTypes,
	exportContactsTypes,
	exportContactsPerCampaignTypes,
	filterRepliesActivityTypes,
	filterStoriesActivityTypes,
	getContactsByCampaignIdTypes,
} from './constants';
import {
	paginationSuccessMapper,
	complexSuccessMapper,
	updateContactsSuccessMapper,
	deleteContactsTagSucessMaper,
	softDeleteContactsSuccessMapper,
	exportContactsSuccessMapper,
	repliesActivityMapper,
	storiesActivityMapper,
} from './mappers';

export default createSlice({
	name: 'contact',
	initialState,
	reducers: {
		NEW_CONTACT_TAGS(state: contactsStateType, action: ResponseAction) {
			action.payload.forEach((contact: any) => {
				if (state?.contacts[contact._id]) {
					state.contacts[contact._id].tags = contact.tags;
				}
			});
		},
	},
	extraReducers: {
		...createFetchReducer(filterContactsTypes, paginationSuccessMapper),
		...createFetchReducer(uploadFlatfileContactsTypes),
		...createFetchReducer(complexFilterContactstypes, complexSuccessMapper),
		...createFetchReducer(updateContactTagTypes, updateContactsSuccessMapper),
		...createFetchReducer(deleteContactTagTypes, deleteContactsTagSucessMaper),
		...createFetchReducer(
			softDeleteContactsTypes,
			softDeleteContactsSuccessMapper,
		),
		...createFetchReducer(exportContactsTypes, exportContactsSuccessMapper),
		...createFetchReducer(
			exportContactsPerCampaignTypes,
			exportContactsSuccessMapper,
		),
		...createFetchReducer(filterRepliesActivityTypes, repliesActivityMapper),
		...createFetchReducer(filterStoriesActivityTypes, storiesActivityMapper),
		...createFetchReducer(
			getContactsByCampaignIdTypes,
			paginationSuccessMapper,
		),
	},
}).reducer;
