import styled from 'styled-components';
import Loader from '../Loader/Loader';

export const AnimationContainer = styled.div<any>`
	width: 35px;
	min-width: 35px;
	path {
		${({ colorFill }) => colorFill && `fill: ${colorFill};`}
	}
`;

export const ToastMessage = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 1em;
	line-height: 1em;
	margin-left: 1em;
	margin-right: 0.5em;
	margin-bottom: 2px;
`;

export const CustomLoader = styled(Loader)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
