import { GenericEntityState } from '../../types';
import { OnBoardingFormType } from '../../../containers/OnBoarding/types';

export const workSpaceLoginInitialState: OnBoardingFormType = {
	storyteller: {
		email: '',
		firstName: '',
		lastName: '',
	},
	workspace: {
		location: 'CA',
		workspaceVertical: '',
		logo: '',
		state: 'active',
		currencyCode: 'cad',
		primaryColor: '',
		secondaryColor: '',
		smsAlert: false,
		communicationPreference: 'email',
		defaultSendingVerified: false,
		defaultSmsSendingNumberVerified: false,
		billingStoryRates: [],
		name: '',
		organizationName: '',
		organizationUrl: '',
		defaultSmsSendingNumber: '',
		onboardingStage: 1,
		templates: [],
		gateways: [],
		integrations: [],
	},
};

export const initialState: GenericEntityState = {
	loading: false,
	response: {
		status: null,
		message: null,
	},
	error: {},
	onBoardingForm: workSpaceLoginInitialState,
};
