import { normalize } from 'normalizr';
import { createSlice } from '@reduxjs/toolkit';

import createFetchReducer from '../../utils/createFetchReducer';
import entityNormalizer from '../../utils/entityNormalizer';
import ObjectToOptions from '../../utils/ObjectToOptions';

import { GenericEntityState, ResponseAction } from '../types';

import {
	saveAudienceTypes,
	getAudienceTypes,
	updateAudienceTypes,
	deleteAudienceTypes,
	getAudienceOptionsTypes,
	getAudienceByIdTypes,
} from './action';

const audienceType = entityNormalizer('audience');
export const pagination = { audience: [audienceType] };

const initialState: GenericEntityState = {
	loading: true,
	error: {},
	response: {
		status: null,
		message: null,
	},
	audience: {},
	audienceCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
		count: null,
	},
	audienceBuildingCount: null,
	audienceOptions: [],
	custom: {},
};

export type audienceStateType = typeof initialState;

export const paginationSuccessMapper = function paginationMapper(
	state: audienceStateType,
	action: ResponseAction,
): void {
	const {
		response: {
			audience,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ audience, page, pageSize, totalPages, count },
		pagination,
	);
	state.audience = { ...state.audience, ...normalizedData.entities.audience };
	state.audienceCount = Object.keys(state.audience)?.length;
	state.pagination.pages[action.response.page] = normalizedData.result.audience;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
};

function updateMapperSuccess(state: audienceStateType, action: ResponseAction) {
	const {
		response: {
			audience: { id, _id },
			audience,
		},
	} = action;
	const audienceId = id || _id;
	state.audience[audienceId] = audience;
}

function deleteMapperSuccess(state: audienceStateType, action: ResponseAction) {
	const {
		response: { audienceId },
	} = action;
	const audienceCopy = { ...state.audience };
	const { [audienceId]: deletedAudienceId, ...restOfAudience } = audienceCopy;
	state.audience = restOfAudience;
}

function normalizeResponse(action: ResponseAction) {
	const {
		response: {
			audience,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ audience, page, pageSize, totalPages, count },
		pagination,
	);
	return normalizedData;
}

function optionsMapper(state: audienceStateType, action: ResponseAction) {
	const normalizedData = normalizeResponse(action);
	const audienceCopy = ObjectToOptions(
		normalizedData?.entities?.audience,
		'id',
		'name',
	);
	audienceCopy?.unshift({
		value: '',
		label: 'All',
	});
	state.audienceOptions = audienceCopy?.length > 1 ? audienceCopy : [];
}

function saveAudienceMapper(state: audienceStateType, action: ResponseAction) {
	const {
		response: { audience },
	} = action;
	state.audience = { [audience?._id]: audience, ...state.audience };
}

const audienceSlice = createSlice({
	name: 'audience',
	initialState,
	reducers: {
		SET_AUDIENCE_BUILDING_COUNT(
			state: audienceStateType,
			action: { payload: number },
		) {
			state.audienceBuildingCount = action.payload;
		},
	},
	extraReducers: {
		...createFetchReducer(saveAudienceTypes, saveAudienceMapper),
		...createFetchReducer(getAudienceTypes, paginationSuccessMapper),
		...createFetchReducer(updateAudienceTypes, updateMapperSuccess),
		...createFetchReducer(deleteAudienceTypes, deleteMapperSuccess),
		...createFetchReducer(getAudienceOptionsTypes, optionsMapper),
		...createFetchReducer(getAudienceByIdTypes),
	},
});

export default audienceSlice.reducer;
