import { Action, createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '../../utils/createFetchReducer';
import {
	uploadVideoTypes,
	filterVideoTypes,
	getStreamingUrlTypes,
	filterVideoWithInfiniteTypes,
	getSingleVideoTypes,
	downloadVideoTypes,
	archiveVideoTypes,
	updateCaptionTypes,
	getParsedCaptionTypes,
	favoriteVideoTypes,
	toggleHideCaptionTypes,
	createCaptionSuggestionTypes,
	deleteCaptionSuggestionTypes,
	getCaptionSuggestionTypes,
} from './action';
import { GenericEntityState, ResponseAction } from '../types';
import { normalize } from 'normalizr';
import entityNormalizer from '../../utils/entityNormalizer';
import { ServiceResponse } from '../../constants';

import {
	CueData,
	SubtitleType,
} from '../../../common/components/interaction/CaptionEditor/types';
import { parse } from 'node-webvtt';
import { CaptionSuggestion } from '../../types';

function videoDataByCueProcessStrategy(
	value: any,
	parent: any,
	key: string,
	excludeTime: boolean,
) {
	if (!value.transcript?.length) {
		return { ...value };
	}
	const dataByCue: { [key: string]: CueData } = {};
	if (
		!value.dataByCue ||
		value.dataByCue[value.transcript?.length - 1].confidence
	) {
		value.transcript?.forEach(
			(
				{
					id,
					text,
					confidence,
				}: {
					id: string;
					confidence: number;
					text: string;
				},
				index: number,
			) => {
				dataByCue[index] = { confidence };
			},
		);
		return { ...value, dataByCue };
	}
}
const video = entityNormalizer(
	'videos',
	{},
	{
		idAttribute: '_id',
		excludeTime: true,
		processStrategy: videoDataByCueProcessStrategy,
	},
);
const pagination = { videos: [video] };

const initialState: GenericEntityState & {
	parsedCaptions: { [key: string]: SubtitleType };
	suggestions?: CaptionSuggestion[];
} = {
	loading: false,
	error: {},
	videos: {},
	videosCount: 0,
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	response: {
		status: null,
		message: null,
	},
	loadingUpload: false,
	processingVideos: {
		current: [],
		currentId: [],
		completed: [],
		showProcessingWidget: false,
	},
	streaming: {},
	custom: {},
	parsedCaptions: {},
};
export type VideoStateType = typeof initialState & GenericEntityState;

function defaultVideosPaginationMapper(
	state: VideoStateType,
	action: ResponseAction,
	cb: (videos: any, state: VideoStateType) => void,
) {
	const {
		response: {
			videos,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;
	const normalizedData = normalize(
		{ videos, page, pageSize, totalPages, count },
		pagination,
	);

	state.pagination.pages[action.response.page] = normalizedData.result.videos;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
	cb(normalizedData.entities.videos, state);
}

function paginationMapper(videos: any, state: VideoStateType) {
	state.videos = { ...videos };
	state.videosCount = Object.keys(state?.videos || {})?.length;
}
function filterMapper(videos: any, state: VideoStateType) {
	if (state.pagination.currentPage === 1) {
		return paginationMapper(videos, state);
	}
	state.videos = { ...state.videos, ...videos };
	state.videosCount = Object.keys(state?.videos || {})?.length;
}

function uploadMapper(state: VideoStateType, action: ResponseAction) {
	state.processingVideos.showProcessingWidget = true;
	state.loadingUpload = false;
}

function getstreamingUrlMapper(state: VideoStateType, action: ResponseAction) {
	const videoId = action.payload.videoId;
	const { url, jwt, accessToken, urlWithProxy } = action.response;
	const newStreamingState = {
		[videoId]: {
			id: videoId,
			streamingUrl: url,
			streamingToken: jwt,
			accessToken,
			urlWithProxy,
		},
	};
	const newState = {
		[videoId]: {
			...state.videos[videoId],
			streamingUrl: url,
			streamingToken: jwt,
		},
	};
	state.videos = { ...state.videos, ...newState };
	state.streaming = { ...state.streaming, ...newStreamingState };
}

function getSingleVideoMapper(state: VideoStateType, action: ResponseAction) {
	const videoId = action.response?.video?._id;
	const normalizedData = normalize(
		{ videos: { ...state.stories, [videoId]: action.response.video } },
		pagination,
	);
	state.videos = { ...normalizedData.entities.videos };
}
function updateCaptionsMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { video, captions },
	} = action;

	if (state.videos[video._id]) {
		state.videos[video._id].captions = video.captions;
		state.videos[video._id].transcriptText = video.transcriptText;

		if (video.dataByCue) {
			state.videos[video._id].dataByCue = video.dataByCue;
		}
	}

	state.parsedCaptions[video._id] = parse(captions, {
		meta: true,
	}) as SubtitleType;
}

function parseCaptionsMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response,
		payload: { id },
	} = action;
	state.parsedCaptions[id] = parse(response, { meta: true }) as SubtitleType;
}

function downLoadVideoMapper(state: VideoStateType, action: ResponseAction) {}

function archiveVideoMapper(state: VideoStateType, action: ResponseAction) {
	state.response.message = ServiceResponse.videoArchived;
}

function favoriteVideoMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { video },
	} = action;
	if (state.videos[video._id]) {
		state.videos[video._id].favorite = video.favorite;
	}
	state.response.message = ServiceResponse.favoriteUpdated;
}

function updateVideoMapper(message: string, field: string = null) {
	return function (state: VideoStateType, action: ResponseAction) {
		const {
			response: { video },
		} = action;

		// Discards uploadedBy
		const { uploadedBy, ...videoFromResponse } = video;

		if (state.videos[video._id]) {
			if (field) {
				state.videos[video._id][field] = videoFromResponse[field];
			} else {
				state.videos[video._id] = {
					...state.videos[video._id],
					...videoFromResponse,
				};
			}
		}

		if (message) {
			state.response.message = message;
		}
	};
}

function getSuggestionsMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { suggestions },
	} = action;
	state.suggestions = suggestions;
}

function deleteSuggestionMapper(state: VideoStateType, action: ResponseAction) {
	const {
		response: { id },
	} = action;
	const stateCopy = [...state.suggestions];
	const toRemove = stateCopy.findIndex(({ objectID }) => objectID === id);
	state.suggestions = stateCopy.splice(toRemove, 1);
}

const videoSlice = createSlice({
	name: 'video',
	initialState,
	reducers: {
		loadingUpload(state: VideoStateType, action: Action) {
			state.loadingUpload = true;
		},
		CLEAR_VIDEOS(state: VideoStateType) {
			state.videos = {};
			state.videosCount = 0;
		},
	},
	extraReducers: {
		...createFetchReducer(uploadVideoTypes, uploadMapper),
		...createFetchReducer(filterVideoTypes, (state: VideoStateType, action) =>
			defaultVideosPaginationMapper(state, action, paginationMapper),
		),
		...createFetchReducer(
			filterVideoWithInfiniteTypes,
			(state: VideoStateType, action) =>
				defaultVideosPaginationMapper(state, action, filterMapper),
		),
		...createFetchReducer(getStreamingUrlTypes, getstreamingUrlMapper),
		...createFetchReducer(getSingleVideoTypes, getSingleVideoMapper),
		...createFetchReducer(downloadVideoTypes, downLoadVideoMapper),
		...createFetchReducer(archiveVideoTypes, archiveVideoMapper),
		...createFetchReducer(updateCaptionTypes, updateCaptionsMapper),
		...createFetchReducer(getParsedCaptionTypes, parseCaptionsMapper),
		...createFetchReducer(favoriteVideoTypes, favoriteVideoMapper),
		...createFetchReducer(
			toggleHideCaptionTypes,
			updateVideoMapper(ServiceResponse.toggledCaptions, 'hideCaptions'),
		),
		...createFetchReducer(createCaptionSuggestionTypes),
		...createFetchReducer(deleteCaptionSuggestionTypes, deleteSuggestionMapper),
		...createFetchReducer(getCaptionSuggestionTypes, getSuggestionsMapper),
	},
});

export const { loadingUpload } = videoSlice.actions;

export default videoSlice.reducer;
