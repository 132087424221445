import { Action } from 'redux';
import { ResponseAction } from '../redux/types';
import { showToast } from '../../common/components/presentation/Toast';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultMapper = (state: { [key: string]: any }, action: Action) => ({});

export default function createFetchReducer(
	types: string[],
	successMapper: MapperFunction = defaultMapper,
	errorMapper: MapperFunction = defaultMapper,
): CreateFetchReducersReturnType {
	// const [REQUEST_TYPE, SUCCESS_TYPE, FAILURE_TYPE] = types;

	// added non desctructuring implementation for storybook
	const actionTypes = types || [];
	const REQUEST_TYPE = actionTypes[0];
	const SUCCESS_TYPE = actionTypes[1];
	const FAILURE_TYPE = actionTypes[2];
	// end of storybook fix

	const reducerObject: ReducerObject = {};

	const handle =
		(loading = false, error = false) =>
		(state: any, action: ResponseAction): void => {
			if (action.payload?.custom) {
				if (action.payload?.resetCustom) {
					state.custom = {
						[`${action.payload?.custom}Loading`]: loading,
						[`${action.payload?.custom}Response`]: loading
							? null
							: action.response,
						[`${action.payload?.custom}Error`]: loading ? null : action.error,
					};
				} else {
					state.custom[`${action.payload?.custom}Loading`] = loading;
					state.custom[`${action.payload?.custom}Response`] = loading
						? null
						: action.response;
					state.custom[`${action.payload?.custom}Error`] = loading
						? null
						: action.error;
				}
			} else {
				state.loading = loading;
				state.response = loading ? null : action.response;
				state.error = loading ? null : action.error;
			}

			const { successMessage, errorMessage } = action.payload;

			if (
				(successMessage && action.response) ||
				(errorMessage && action.error)
			) {
				const message = error ? errorMessage : successMessage;
				showToast(message, error ? 'error' : 'success');
			}

			if (!loading) {
				state = error
					? errorMapper(state, action)
					: successMapper(state, action);
			}
		};
	reducerObject[REQUEST_TYPE] = handle(true);
	reducerObject[SUCCESS_TYPE] = handle(false);
	reducerObject[FAILURE_TYPE] = handle(false, true);

	return reducerObject;
}
type ReducerObject = { [key: string]: (state: any, action: any) => void };
type MapperFunction = (
	state: { [key: string]: any },
	action: ResponseAction,
) => void;

type CreateFetchReducersReturnType = { [key: string]: any };
