/* eslint-disable @typescript-eslint/camelcase */
import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import {
	BBAuthTypes,
	BBRefreshTypes,
	BBListTypes,
	BBCampaignTypes,
	BBFundTypes,
	BBAppealTypes,
	BBMSAccountTypes,
	BBPublicKeyTypes,
	GetConstituentOfListsTypes,
} from './action';

const initialState: GenericEntityState = {
	loading: true,
	blackbaudToggle: false,
	insufficientData: false,
	blackbaudIntegrationRefresh: {
		type: 'integration',
		access_token: '',
		refresh_token: '',
		refresh_token_expires_in: '',
		environment_id: '',
	},
	blackbaudGatewayRefresh: {
		type: 'gateway',
		access_token: '',
		refresh_token: '',
		refresh_token_expires_in: '',
		environment_id: '',
	},
	blackbaudIntegrationAuth: {
		type: 'integration',
		access_token: '',
		refresh_token: '',
		refresh_token_expires_in: '',
		environment_id: '',
	},
	blackbaudGatewayAuth: {
		type: 'gateway',
		access_token: '',
		refresh_token: '',
		refresh_token_expires_in: '',
		environment_id: '',
	},
	blackbaudListConstituents: [''],
	bbLists: [''],
	bbAppeals: new Array<string>(0),
	bbFunds: new Array<string>(0),
	bbCampaign: new Array<string>(0),
	bbmsAccount: [],
	bbPublicKey: '',
	response: {
		status: null,
		message: null,
	},
	error: {},
};

export type blackbaudStateType = typeof initialState & GenericEntityState;

function blackbaudAuthMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const {
		response,
		payload: { type },
	} = action;
	if (type === 'blackbaud') {
		state.blackbaudIntegrationAuth = { type, ...response };
	} else {
		state.blackbaudGatewayAuth = { type, ...response };
	}
}

function blackbaudRefreshMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const {
		response,
		payload: { type },
	} = action;
	if (type === 'blackbaud') {
		state.blackbaudIntegrationRefresh = response;
	} else {
		state.blackbaudGatewayRefresh = response;
	}
}

function blackbaudListMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response } = action;
	if (response && response.count) {
		state.bbLists = response.value;
	}
}

function blackbaudCampaignMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response } = action;
	if (response) {
		if (response.count) {
			const { value } = response;
			const arr: any = [];
			value.map((item: any) => {
				const endDate = new Date(item.end_date).getTime();
				const todayDate = Date.now();
				if (item.end_date && endDate >= todayDate) {
					arr.push(item);
				}
			});
			state.bbCampaign = arr;
			if (!arr.length) {
				state.insufficientData = true;
			}
		} else {
			state.insufficientData = true;
		}
	}
}

function blackbaudAppealMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response } = action;
	if (response?.count) {
		const { value } = response;
		const arr: any = [];
		value.map((item: any) => {
			const endDate = new Date(item.end_date).getTime();
			const todayDate = Date.now();
			if (item.end_date) {
				if (endDate >= todayDate) {
					arr.push(item);
				}
			}
		});
		state.bbAppeals = arr;
		if (!arr.length) {
			state.insufficientData = true;
		}
	} else {
		state.insufficientData = true;
	}
}

function blackbaudFundMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response } = action;
	if (response) {
		if (response.count) {
			const { value } = response;
			state.bbFunds = value;
			if (!value.length) {
				state.insufficientData = true;
			}
		} else {
			state.insufficientData = true;
		}
	}
}

function blackbaudBBMSMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response } = action;
	if (response.count) {
		state.bbmsAccount = response.value;
	}
}

function blackbaudPublicKeyMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response } = action;
	if (response.public_key) {
		state.bbPublicKey = response.public_key;
	}
}

function getConstituentsMapper(
	state: blackbaudStateType,
	action: ResponseAction,
) {
	const { response, payload } = action;
	if (response && response.count) {
		if (payload && payload[0] && payload.length) {
			const arr = payload;
			state.blackbaudListConstituents = [...arr, ...response.value];
		} else {
			state.blackbaudListConstituents = response.value;
		}
	}
}

const BBAuthSlice = createSlice({
	name: 'BBAuth',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(BBAuthTypes, blackbaudAuthMapper),
		...createFetchReducer(BBRefreshTypes, blackbaudRefreshMapper),
		...createFetchReducer(BBListTypes, blackbaudListMapper),
		...createFetchReducer(BBCampaignTypes, blackbaudCampaignMapper),
		...createFetchReducer(BBAppealTypes, blackbaudAppealMapper),
		...createFetchReducer(BBFundTypes, blackbaudFundMapper),
		...createFetchReducer(BBMSAccountTypes, blackbaudBBMSMapper),
		...createFetchReducer(BBPublicKeyTypes, blackbaudPublicKeyMapper),
		...createFetchReducer(GetConstituentOfListsTypes, getConstituentsMapper),
	},
});

export default BBAuthSlice.reducer;
