import createFetchTypes from '../../utils/createFetchTypes';
import { fetchAction } from '../types';
import { Action } from '@reduxjs/toolkit';
import axios, { axiosForExternal } from '../../../global/axios';
// import axiosFromLibrary from 'axios';
import { apiUrl, ToastMessage } from '../../constants';
import { CaptionSuggestion, FilterOptions } from '../../types';
import { CueData } from '../../../common/components/interaction/CaptionEditor/types';
const mainType = 'video';

export const uploadVideoTypes = createFetchTypes(`${mainType}_UPLOAD`);

function uploadVideo(formdata: FormData, crop?: boolean): fetchAction {
	return {
		type: uploadVideoTypes,
		callAPI: () =>
			axios.post(
				`${apiUrl}/video?inputlanguage=en-US${crop ? '&square=true' : ''}`,
				formdata,
			),
		payload: {
			custom: 'videoUpload',
		},
	};
}

export const filterVideoTypes = createFetchTypes(`${mainType}_FILTER_VIDEOS`);
export const filterVideoWithInfiniteTypes = createFetchTypes(
	`${mainType}_FILTER_VIDEOS_WITH_INFINITE`,
);

function filterVideos(
	filterOptions: FilterOptions,
	infinite = false,
): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: infinite ? filterVideoWithInfiniteTypes : filterVideoTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/video${filterOptions ? `?filter=${filterString}` : ``}`,
			),
		payload: {
			errorMessage: ToastMessage.error,
		},
	};
}

export const downloadVideoTypes = createFetchTypes(
	`${mainType}_DOWNLOAD_VIDEOS`,
);

function downloadVideo(videoId: string) {
	return {
		type: downloadVideoTypes,
		callAPI: () => axios.get(`${apiUrl}/video/download/${videoId}`),
	};
}

export const archiveVideoTypes = createFetchTypes(`${mainType}_ARCHIVE_VIDEO`);

function archiveVideo(videoId: string) {
	return {
		type: archiveVideoTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${videoId}`, { video: { deleted: true } }),
	};
}

export const toggleHideCaptionTypes = createFetchTypes(
	`${mainType}_TOGGLE_CAPTIONS`,
);

function toggleHideCaptions(videoId: string, hideCaptions: boolean) {
	return {
		type: toggleHideCaptionTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${videoId}`, { video: { hideCaptions } }),
	};
}

export const favoriteVideoTypes = createFetchTypes(
	`${mainType}_FAVORITE_VIDEO`,
);

function favoriteVideo(videoId: string, isFavorite = false) {
	return {
		type: favoriteVideoTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/${videoId}`, {
				video: { favorite: isFavorite },
			}),
		payload: {
			custom: 'favoriteVideo',
			successMessage: isFavorite
				? ToastMessage.favorite
				: ToastMessage.favoriteUpdated,
			errorMessage: ToastMessage.error,
		},
	};
}

export const toggleShowWidgetTypes = `${mainType}_TOGGLE_SHOW_WIDGET`;

function toggleShowWidget(): Action {
	return {
		type: toggleShowWidgetTypes,
	};
}

export const getStreamingUrlTypes = createFetchTypes(
	`${mainType}_GET_STREAMING_URL`,
);

function getStreamingUrl(
	videoId: string,
	azureId: string,
	proxy: boolean,
	location: string,
) {
	return {
		type: getStreamingUrlTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/video/getStreamingUrl?videoId=${azureId}&proxy=${proxy}&location=${location}`,
			),
		payload: { videoId },
	};
}

export const getSingleVideoTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_VIDEO`,
);

function getSingleVideo(videoId: string) {
	return {
		type: getSingleVideoTypes,
		callAPI: () => axios.get(`${apiUrl}/video/${videoId}`),
	};
}

export const clearVideosType = 'video/CLEAR_VIDEOS';

function clearVideos() {
	return {
		type: clearVideosType,
	};
}

export const updateCaptionTypes = createFetchTypes(
	`${mainType}_UPDATE_CAPTIONS`,
);

function updateCaptions(
	videoId: string,
	captions: string,
	language: string,
	dataByCue: { [key: string]: CueData },
) {
	return {
		type: updateCaptionTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/video/captions/${videoId}`, {
				captions,
				language,
				dataByCue,
			}),
		payload: {
			custom: 'updateCaptions',
		},
	};
}

export const getParsedCaptionTypes = createFetchTypes(
	`${mainType}_GET_PARSED_CAPTION`,
);

function getParsedCaptions(url: string, videoId: string) {
	return {
		type: getParsedCaptionTypes,
		callAPI: () => axiosForExternal.get(url),
		payload: {
			custom: 'getParsedCaption',
			id: videoId,
		},
	};
}

export const createCaptionSuggestionTypes = createFetchTypes(
	`${mainType}_CREATE_CAPTION_SUGGESTION`,
);

function createSuggestion(suggestion: CaptionSuggestion) {
	return {
		type: createCaptionSuggestionTypes,
		callAPI: () => axios.post(`${apiUrl}/video/suggestion`, { suggestion }),
		payload: {
			custom: 'createSuggestion',
			successMessage: ToastMessage.suggestionCreated,
			errorMessage: ToastMessage.error,
		},
	};
}

export const deleteCaptionSuggestionTypes = createFetchTypes(
	`${mainType}_DELETE_CAPTION_SUGGESTION`,
);

function deleteSuggestion(suggestionId: string) {
	return {
		type: deleteCaptionSuggestionTypes,
		callAPI: () => axios.delete(`${apiUrl}/video/suggestion/${suggestionId}`),
		payload: {
			custom: 'deleteSuggestion',
			successMessage: ToastMessage.suggestionDeleted,
			errorMessage: ToastMessage.error,
		},
	};
}

export const getCaptionSuggestionTypes = createFetchTypes(
	`${mainType}_GET_CAPTION_SUGGESTION`,
);

function getSuggestions(filterOptions?: FilterOptions): fetchAction {
	const filterString = JSON.stringify(
		filterOptions || { search: '', pagination: { page: 1, pageSize: 100 } },
	);
	return {
		type: getCaptionSuggestionTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/video/suggestion${
					filterString ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'getSuggestion',
		},
	};
}

export {
	filterVideos,
	uploadVideo,
	toggleShowWidget,
	getStreamingUrl,
	clearVideos,
	getSingleVideo,
	downloadVideo,
	archiveVideo,
	favoriteVideo,
	updateCaptions,
	getParsedCaptions,
	toggleHideCaptions,
	createSuggestion,
	getSuggestions,
	deleteSuggestion,
};
