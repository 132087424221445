import _axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { apiUrl } from '../app/constants';
import store from '../app/redux/store';
import TokenStorage from '../app/utils/TokenStorage';
import { CLEAR_AUTH } from '../app/redux/auth';

const axios = _axios.create({
	baseURL: apiUrl,
	// Modify this
	timeout: 1000 * 7200, // 7200 seconds
	responseType: 'json',
});

export const axiosForExternal = _axios.create({
	baseURL: apiUrl,
	// Modify this
	timeout: 1000 * 7200, // 7200 seconds
	// responseType: 'json',
});

axios.interceptors.request.use((config: AxiosRequestConfig) => {
	const identityToken =
		store.getState().auth.identityToken || TokenStorage.getIdentity();
	const accessToken = TokenStorage.getAccessToken();
	if (identityToken) {
		config.headers['Identity'] = identityToken;
	}
	if (accessToken) {
		if (!config.headers.Authorization) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
	}

	return config;
});

axios.interceptors.response.use(
	(res: AxiosResponse) => {
		return res;
	},
	(error: AxiosError) => {
		const { response } = error;

		if (
			response.status === 401 ||
			(response.status === 500 &&
				response.data.message &&
				(response.data.message.includes('authorization') ||
					response.data.message.includes('jwt expired') ||
					response.data.message.includes('invalid')))
		) {
			// LocalStorage.clear();
			// console.log('logging out', authConfig.logoutUrl);
			// store.dispatch(CLEAR_AUTH());
		}
		return Promise.reject(error);
	},
);

export default axios;
