export enum operators {
	greaterThan = 'gt',
	greaterThanEquals = 'gte',
	lesserThan = 'lt',
	lesserThanEquals = 'lte',
	not = 'not',
	exists = 'exists',
}

export enum orderTypes {
	ascending = 'asc',
	descending = 'desc',
}
export const sortDropdownOptions = [
	{
		key: 'engagementRate',
		text: 'Most engaging',
		value: 'engagementRate',
	},
	{
		key: 'storyViews',
		text: 'Most views',
		value: 'storyViews',
	},
	{
		key: 'totalTransactedSum',
		text: 'Most donations',
		value: 'totalTransactedSum',
	},
];

export const getGoodkindVersion = process.env.VERSION;

export const apiUrl = process.env.API_URL;

export const webRecordingUrl = process.env.WEB_RECORDING_URL;

export const mixpanelToken = process.env.MIXPANEL_TOKEN;

function getVideoPageUrlDomain() {
	switch (process.env.NODE_ENV) {
		case 'stage':
			return 'givemagicvideopageproduction-stage.azurewebsites.net';

		case 'qa':
			return 'givemagicvideopageproduction-qa.azurewebsites.net';

		case 'production':
		case 'prod':
			return 'goodkind.tv';

		default:
			return 'givemagicvideopageproduction-qa.azurewebsites.net';
	}
}

export const videoPageUrlDomain = getVideoPageUrlDomain();
export const storytellerAlgoliaId = process.env.ALGOLIA_STORYTELLER_INDEX_NAME;

export const videosAlgoliaId = process.env.ALGOLIA_VIDEO_INDEX_NAME;

export const hotglueApiKey = process.env.HOTGLUE_API_KEY;

export const hotglueEnvId = process.env.HOTGLUE_ENV_URL;

export enum ToastMessage {
	success = 'Success',
	error = 'There was an error, can you please try again',
	loading = 'Loading...',
	errorUpdatingWorkspace = 'There has been an error updating the workspace, please try again',
	errorAuthenticatingBlackbaud = 'You’ll need to authenticate with Blackbaud in order to send a Blackbaud donation',
	smsOff = 'One of the contacts has SMS preference, please turn SMS messaging on in Settings before sending video',
	smsSent = 'SMS sent',
	updateWorkspace = 'You’ve successfully updated your Workspace!',
	copied = 'Copied!',
	failedToCopy = 'Failed to copy!',
	archive = 'Successfully archived',
	numbersNotAllowed = 'Numbers not allowed',

	/**CTA*/
	createCTA = 'You have successfully created a new action',
	updateCTA = 'You have successfully updated an action',
	errorCreatingCTA = 'There has been an error creating the new action, can you please try again',
	ctaNotSelected = 'There is no CTA selected',

	/**Delete modal*/
	deletedAssignStory = 'You’ve deleted an assigned video',

	/**Edited assigned story*/
	editedAssignedStory = 'You’ve edited an assigned video',

	/**Campaign wizard*/
	campaignUpdated = 'Your campaign has been updated',
	createdCampaign = 'Campaign created',
	savedDraftCampaign = 'Campaign saved as draft',
	errorCreatingCampaign = 'There has been an error creating campaign, please try again',
	errorSendingAddress = 'Workspace requires default sending address to be verified in order to launch campaigns',
	campaignLaunched = 'Campaign successfully launched',
	audienceCreated = 'Audience successfully created',
	audienceUpdated = 'Audience successfully updated',
	audienceDeleted = 'Audience successfully deleted',
	audienceSelected = 'Audience successfully selected',
	errorCreatingAudience = 'There has been an error creating audience, please try again',
	scriptSaved = 'Script saved',
	campaignNameUpdated = 'Campaign name has been updated',
	campaignUpdatedNameDuplicated = 'Campaign updated. Warning: Campaign name already exists',
	campaignNameDuplicatedWarning = 'Warning: Campaign name already exists',
	ctaCreatedAndSelected = 'Action created and selected',
	deactivatedCampaign = 'Campaign deactivated successfully',
	draftCampaignsDeleted = 'Draft campaign deleted successfully',

	/** Audience*/
	audienceMaxContactError = 'The contacts selected exceed the permitted for this campaign',

	/** Campaign summary*/
	previewEmailSent = 'We’ve sent you a preview email from your new campaign',
	errorSendingPreview = 'There was an error sending the email preview',

	/**Triggers*/
	errorFillLastTrigger = 'Fill the last trigger',

	/**Resend stories*/
	storyResent = 'Campaign videos successfully resent',
	errorResendingStories = 'There has been an error resending videos.',

	/** Import contacts*/
	uploadContacts = 'Contacts successfully imported',

	/** Contacts */
	deletedContacts = 'Contacts deleted',
	assignedStoryCreated = 'Assigned video created',
	errorContactSendingAddress = 'Workspace requires default sending address to be verified in order to create a video message',
	contactAlreadyExistInCampaign = 'Contact already exists in campaign',
	unableToAddContact = 'Unable to add contact to this type of campaign.',

	/*** Video */
	videoSent = 'Video sent',
	sendingVideo = 'Sending video...',
	videoUploaded = 'Successfully uploaded video!',
	captionsUpdated = 'Captions updated',
	favorite = 'Marked as favorite',
	favoriteUpdated = 'Favorite updated',

	/** Story */
	storyCreated = 'Video created successfully',
	errorCreatingStory = 'Error creating video',
	storyEnRoute = 'Your video is now en route',
	errorAssigningStory = 'Error assigning video',
	storySent = 'Video sent successfully',
	errorSendingStory = 'Error sending video',

	/**Login*/
	errorPermissionDenied = 'Permission denied. You do not have administrator access.',
	loggedIn = 'You have now successfully logged in.',
	errorLoginFirst = 'You must log in first to be able to use the platform!',

	/**Storyteller*/
	storytellerUpdated = 'Team member updated successfully',
	errorCreatingStoryteller = 'Error inviting team member',
	storytellerCreated = 'Team member has been invited successfully',
	errorEditingStoryteller = 'There has been an error editing the team member',
	storytellerDeleted = 'Team members deleted successfully',

	/** Onboarding */
	errorWorkspaceAlreadyCreated = 'Workspace already created',
	colorsUpdated = 'Colors updated',
	errorAddValidaStoryteller = 'Please add a valid storyteller',
	onBoardingComplete = 'Congratulations, you’re now ready to use Goodkind.',

	/** Blackbaud*/
	alreadyAuthenticated = 'Already authenticated',
	errorSelectBBMS = 'Please select BBMS account ID',

	/** Integration*/
	gatewayConnected = 'Gateway connected',
	integrationConnected = 'Integration connected',
	integrationUpdated = 'Integration updated',
	gatewayDisconnected = 'Gateway disconnected',
	integrationDisconnected = 'Integration disconnected',

	/** Workspace*/
	workspaceUpdated = 'Workspace updated',
	workspaceCreated = 'Workspace created!',
	errorWorkspaceEmail = 'Workspace requires default sending address to be verified in order to create a video.',
	errorEmailAddressWorkspaceSelection = 'Incorrect email address. Does not match storyteller on record.',
	profilePictureUploaded = 'Successfully uploaded profile picture',
	customAttributeCreated = 'Custom attribute successfully created',
	customAttributeMapped = 'Custom attribute succesfully mapped',
	domainVerified = 'Email address domain successfully verified',
	errorDomainAuthentication = 'Email address domain not authenticated',
	errorDomainNotFound = 'Email address domain not found',

	/** CardStorytellers email verification */
	resendVerificationSuccess = 'Verification successfully resent',
	verificationProgress = 'Storyteller verification in progress',
	verificationSuccess = 'Storyteller verification is successful',

	/** Upload*/
	errorVideoUpload = 'Error uploading, the video can not be more than 100MB',
	contactsToCampaignAdded = 'Contacts successfully added to this campaign.',
	contactsToCampaignError = 'You have selected one or more contacts that are already part of this campaign.',
	updatedSettings = 'Settings updated ',

	/** Tags */
	tagUpdated = 'Tag successfully updated',
	tagDeleted = 'Tag successfully deleted',
	tagsUpdated = 'Tags successfully updated',
	tagsCreated = 'Tags successfully created',
	tagsDeleted = 'Tags successfully deleted',

	/** Campaign Story Templates */
	storyTemplateUpdated = 'Template successfully updated',
	storyTemplateCreated = 'Template successfully created',

	/** Story/Reply details */
	errorStoryNotFound = 'Video not found',
	errorReplyNotFound = 'Reply not found',
	updatedReply = 'Successfully updated reply',
	updatedReplies = 'Successfully updated replies',
	singleReplyStoryDeleted = 'Reply deleted',
	severalReplyStoriesDeleted = 'Replies deleted',
	singleScheduledStoryDeleted = 'Scheduled video deleted',
	severalScheduledStoriesDeleted = 'Scheduled videos deleted',
	singleApprovalStoryDeleted = 'Video deleted',
	severalApprovalStoriesDeleted = 'Videos deleted',
	singleAssignedStoryDeleted = 'Assigned video deleted',
	severalAssignedStoriesDeleted = 'Assigned video deleted',
	singleStoryArchived = 'Sent video archived',
	severalStoriesArchived = 'Sent videos archived',
	singleStoryUnarchived = 'Sent video unarchived',
	severalStoriesUnarchived = 'Sent videos unarchived',

	/** Approvals */
	storyRejected = 'Rejected successfully',

	/** Template **/
	duplicatedTemplateNameError = 'Name of template already exists. Try another name',
	manualDistributionError = 'Please check your assignment percentage',
	backgroundRequired = 'Background image is missing in the template',
	logoRequired = 'Logo is missing in the template',
	backgroundAndLogoRequired = 'Background image and logo are missing in the template',

	/** Captions Settings */
	threeWordsNotAllowed = 'Keywords can only have maximum of 2 words',

	/** Automations */
	automationNotFound = 'Automation not found',
	createAutomation = "You've succesfully created your automation",
	editAutomation = "You've successfully edited your automation",

	/** Groups */
	groupNotFound = 'Group not found',
	createGroup = "You've succesfully created your group",
	groupWithoutStorytellers = 'Every group needs at least one storyteller for assignment.',

	// Caption suggestion
	suggestionCreated = 'Suggestion created',
	suggestionDeleted = 'Suggestion removed',

	/** Delivery Channels */
	deliveryChannelUpdated = 'Delivery Channel updated successfully',
}

export const ToastPosition = {
	top: '100px',
	right: '36px',
	width: 'auto',
};

export const GoodKindApp = {
	Android: 'https://goodkind.app',
	IOS: 'https://goodkind.app',
};

export enum ServiceResponse {
	saveDraft = 'draft saved',
	videoArchived = 'video archived',
	favoriteUpdated = 'favorite updated',
	toggledCaptions = 'Captions toggled',
}

export enum MixpanelEvent {
	viewedPage = 'Viewed Page',

	createdRecipe = 'Created drafts from recipe',

	createdOneToOneCampaign = 'Clicked create a One to One campaign',
	createdOneToAllCampaign = 'Clicked create a One to All campaign',
	createdCollectVideosCampaign = 'Clicked create a Collect Videos campaign',
	completedAction = 'Completed campaign call to action step',
	completedTemplate = 'Completed campaign template step',
	completedStoryteller = 'Completed campaign storyteller step',
	completedInstructions = 'Completed campaign Instruction step',
	completedAudience = 'Completed campaign audience step',
	completedTrigger = 'Completed campaign Trigger step',
	completedVideo = 'Completed campaign video step',

	completedCampaignTypeStep = 'Completed campaign type step',

	createdWorkspace = 'Created Workspace',

	changedWorkspace = 'Changed Workspace',

	launchedCampaign = 'Launched Campaign',
	launchedDraftCampaign = 'Launched Draft Campaign',
	launchedScheduledCampaign = 'Launched Scheduled Campaign',
	savedCampaign = 'Saved Campaign',
	draftCampaign = 'Draft Campaign',
	renameCampaign = 'Campaign name changed',
	abandonedCampaign = 'Abandoned Campaign',
	deactivatedCampaignNoStories = 'Campaign deactivated with no stories',
	deactivatedCampaignDelete = 'Campaign deactivated and delete stories',
	deactivatedCampaignFinish = 'Campaign deactivated and finish stories',
	updatedCampaignPreview = 'Updated campaign preview',
	updatedEmailTemplate = 'Updated email template',
	campaignInstructions = 'Instructions added',
	previewSent = 'Sent preview',

	storytellerChanged = 'Campaign builder storyteller changed',

	login = 'Login',

	invitedStoryteller = 'Invited Storyteller',
	editedStoryteller = 'Edited Storyteller',

	verifiedStoryteller = 'Verified Storyteller',
	resentVerification = 'Resent Verification',
	checkedVerification = 'Checked Verification',

	createdCTA = 'Created CTA',
	updatedCTA = 'Updated CTA',
	deletedCTA = 'Deleted CTA',

	deletedContacts = 'Deleted Contacts',

	openedAudienceBuilder = 'Opened Audience Builder',
	createdAudience = 'Created Audience',
	previewAudience = 'Previewed Audience',

	storyTemplateCreated = 'New template created',
	storyTemplateDefaultSelected = 'Default template selected',
	storyTemplateSelected = 'Existing template selected',
	newAction = 'New action created',

	deletedAssignedStories = 'Deleted Assigned Videos',
	editedAssignedStoryteller = 'Edited Assigned Storyteller',
	rejectedApprovalStories = 'Rejected Approval Videos',
	approvedApprovalStories = 'Approved Approval Videos',
	sawApprovalStoryPreview = 'Saw Approval Videos Preview',
	deletedScheduledStories = 'Deleted Scheduled Videos',
	sawScheduledStoryPreview = 'Saw Scheduled Video Preview',
	assignedStoryReply = 'Assigned Video Reply',
	closedStoryReply = 'Closed Video Reply',
	deletedStoryReply = 'Deleted Video Reply',
	reopenedReply = 'Reopened Video Reply',
	addedSentStoryContactsToCampaign = 'Added Sent Video Contacts To Campaign',
	sawSentStoryPreview = 'Saw Sent Video Preview',
	archivedSentStory = 'Archived Sent Video',
	unarchivedSentStory = 'Unarchived Sent Video',

	updatedCaptions = 'Updated Captions',
	contactsImported = 'Contacts imported',
	campaignContactsAdded = 'Contacts added to campaign',

	recipeOpened = 'Opened recipe',
	videoUploaded = 'Uploaded video',
	campaignVideoUploaded = 'Uploaded video in campaign builder',

	draftOpened = 'Opened Draft Campaign',

	assignedStory = 'New Video Assigned',
	sentVideo = 'Video Sent From Library',

	getHelp = 'Get help',
}

export const intercomAppId = 'kez33khf';

export const engagementScoreHint =
	'Engagement rate takes into account the % of the video watched, hearting, shares & more.';
