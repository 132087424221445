import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl, ToastMessage } from '../../constants/index';
import { fetchAction, GenericAPIResponse } from '../types';
import { FilterOptions, saveCampaignCustomName } from '../../types/index';
import axios from '../../../global/axios';
import { CampaignDropdownFilter } from '../constants';
import { CampaignType } from './types';
const mainType = 'campaign';
type campaign = {
	[key: string]: any;
};

export const getCampaignTypes = createFetchTypes(`${mainType}_GET_CAMPAIGN`);

export const getCampaignsCountTypes = createFetchTypes(
	`${mainType}_GET_CAMPAIGNS_COUNT`,
);

export const filterCampaignsTypes = createFetchTypes(
	`${mainType}_FILTER_CAMPAIGNS`,
);
export const getCampaignOptionsTypes = createFetchTypes(
	`${mainType}_GET_CAMPAIGNS_OPTIONS`,
);

export const deleteCampaignsTypes = createFetchTypes(
	`${mainType}_DELETE_CAMPAIGNS`,
);
export const createCampaignTypes = createFetchTypes(
	`${mainType}_CREATE_CAMPAIGNS`,
);
export const updateCampaignTypes = createFetchTypes(
	`${mainType}_UPDATE_CAMPAIGNS`,
);
export const createDraftCampaignTypes = createFetchTypes(
	`${mainType}_CREATE_DRAFT_CAMPAIGNS`,
);
export const launchDraftCampaignTypes = createFetchTypes(
	`${mainType}_LAUNCH_DRAFT_CAMPAIGNS`,
);
export const addContactsToCampaignTypes = createFetchTypes(
	`${mainType}_ADD_CONTACT_TO_CAMPAIGN`,
);
export const deactivateCampaignTypes = createFetchTypes(
	`${mainType}_DEACTIVATE`,
);
export const bulkDeleteDraftCampaignsTypes = createFetchTypes(
	`${mainType}_DELETE_DRAFT_CAMPAIGNS`,
);

export const getVerifiedStorytellersTypes = createFetchTypes(
	`${mainType}_GET_VERIFIED_STORYTELLERS`,
);

function deactivateCampaign(deactivateCampaignObject: {
	campaignId: string;
	deleteAll: boolean;
}): fetchAction {
	return {
		type: deactivateCampaignTypes,
		callAPI: () =>
			axios.patch(`${apiUrl}/campaign/deactivate`, deactivateCampaignObject),
		payload: {
			custom: 'deactivateCampaign',
			successMessage: ToastMessage.deactivatedCampaign,
			errorMessage: ToastMessage.error,
		},
	};
}

function getVerifiedStorytellers(campaignId: string): fetchAction {
	return {
		type: getVerifiedStorytellersTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/campaign/storytellers/address/${campaignId}`),
		payload: {
			custom: 'getVerifiedStorytellers',
		},
	};
}

function getCampaign(campaignId: string): fetchAction {
	return {
		type: getCampaignTypes,
		callAPI: () => axios.get(`${apiUrl}/campaign/${campaignId}`),
		payload: {
			custom: 'getCampaign',
		},
	};
}

function getCampaignsCount() {
	return {
		type: getCampaignsCountTypes,
		callAPI: () => axios.get(`${apiUrl}/campaign/count`),
		payload: { custom: 'getCampaignsCount' },
	};
}

function filterCampaigns(
	FilterOptions: FilterOptions,
	options?: { isFilter: boolean },
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterCampaignsTypes,
		payload: { isFilter: options?.isFilter },
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

function getCampaignOptions(
	options: {
		excludeAll?: boolean;
		includeBoth?: boolean;
		filterOptions?: FilterOptions;
	} = {},
): fetchAction {
	return {
		type: getCampaignOptionsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign?filter=${JSON.stringify(
					options.filterOptions
						? options.filterOptions
						: CampaignDropdownFilter,
				)}`,
			),
		payload: {
			excludeAll: options.excludeAll,
			includeBoth: options.includeBoth,
		},
	};
}

function deleteCampaigns(DeleteOptions: string[]): fetchAction {
	const deleteString = JSON.stringify(DeleteOptions);
	return {
		type: deleteCampaignsTypes,
		callAPI: () =>
			axios.delete(
				`${apiUrl}/campaign${DeleteOptions ? `?filter=${deleteString}` : ``}`,
			),
	};
}

function bulkDeleteDraftCampaigns(campaigns: CampaignType[]): fetchAction {
	return {
		type: bulkDeleteDraftCampaignsTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/campaign/bulkDeleteDraft`, {
				campaigns,
			}),
		payload: {
			custom: 'bulkDeleteDraft',
			successMessage: ToastMessage.draftCampaignsDeleted,
		},
	};
}

function createCampaign(createCampaignObject: { [key: string]: any }) {
	return {
		type: createCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign`, createCampaignObject),
	};
}

function updateCampaign(createCampaignObject: { [key: string]: any }) {
	return {
		type: updateCampaignTypes,
		callAPI: () => axios.put(`${apiUrl}/campaign`, createCampaignObject),
		payload: {
			custom: 'updateCampaign',
			successMessage: ToastMessage.campaignUpdated,
			errorMessage: ToastMessage.error,
		},
	};
}

function createDraftCampaign(createCampaignObject: { [key: string]: any }) {
	return {
		type: createDraftCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/draft`, createCampaignObject),
	};
}

function launchDraftCampaign(createCampaignId: string) {
	return {
		type: launchDraftCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/launch/${createCampaignId}`),
	};
}

export const exportCampaignsTypes = createFetchTypes(
	`${mainType}_EXPORT_CAMPAIGNS`,
);
function exportCampaigns(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: exportCampaignsTypes,
		callAPI: () =>
			axios({
				url: `${apiUrl}/campaign/export${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
				method: 'GET',
				responseType: 'blob',
			}),
		payload: {
			custom: 'exportCampaigns',
		},
	};
}

export const exportSingleCampaignTypes = createFetchTypes(
	`${mainType}_EXPORT_SINGLE_CAMPAIGN`,
);
function exportSingleCampaign(campaign: any): fetchAction {
	const paginationObject = {
		page: 1,
		pageSize: 1,
	};

	const populateObject = [
		{ field: 'callToAction', select: [] },
		{
			field: 'storytellers',
			select: [
				'firstName',
				'lastName',
				'fullName',
				'storiesSent',
				'assignedStories',
				'storiesSaved',
			],
		},
		{
			field: 'tags',
			select: ['text', 'color', '_id'],
		},
		{
			field: 'audience',
			select: ['_id', 'name'],
		},
	];

	const filterOject = [{ field: '_id', value: campaign._id }];

	const FilterOptions = {
		pagination: paginationObject,
		populate: populateObject,
		filter: filterOject,
	};

	return {
		type: exportSingleCampaignTypes,
		payload: { campaignName: campaign.name, custom: 'exportSingleCampaign' },
		callAPI: () =>
			axios({
				url: `${apiUrl}/campaign/export?filter=${JSON.stringify(
					FilterOptions,
				)}`,
				method: 'GET',
				responseType: 'blob',
			}),
	};
}

export const emailPreviewTypes = createFetchTypes(`${mainType}_EMAIL_PREVIEW`);
function sendEmailPreview(
	data: {
		videoId?: string;
		ctaId: string;
		emailSubjectTitle?: string;
		smsBody?: string;
		sendThroughDefault: boolean;
		storytellerId?: string;
	},
	noEmail = false,
): fetchAction {
	return {
		type: emailPreviewTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/stories/preview${noEmail ? '?noEmail=true' : ''}`, {
				...data,
			}),
		payload: {
			custom: 'sendPreview',
			...(noEmail
				? {}
				: {
						successMessage: ToastMessage.previewEmailSent,
						errorMessage: '',
				  }),
		},
	};
}

export const resendCampaignStoriesTypes = createFetchTypes(
	`${mainType}_RESEND_CAMPAIGN_STORIES`,
);

function resendCampaignStories(data: {
	storiesToEmailNotOpened: boolean;
	storiesToStoryNotPlayed: boolean;
	storiesToVideoNotOpened: boolean;
	campaignId: string;
}): fetchAction {
	return {
		type: resendCampaignStoriesTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/resend`, data),
	};
}

export const resendStoriesCounttypes = createFetchTypes(
	`${mainType}_RESEND_STORIES_COUNT`,
);

function resendStoriesCount({
	storiesToEmailNotOpened,
	storiesToStoryNotPlayed,
	storiesToVideoNotOpened,
	campaignId,
}: {
	storiesToEmailNotOpened: boolean;
	storiesToStoryNotPlayed: boolean;
	storiesToVideoNotOpened: boolean;
	campaignId: string;
}): fetchAction {
	return {
		type: resendStoriesCounttypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/campaign/resend/count/?campaignId=${campaignId}&storiesToEmailNotOpened=${storiesToEmailNotOpened}&storiesToStoryNotPlayed=${storiesToStoryNotPlayed}&storiesToVideoNotOpened=${storiesToVideoNotOpened}`,
			),
		payload: {
			custom: 'resendStoriesCount',
		},
	};
}

const addContactToCampaign = (
	values: {
		campaign: string;
		contacts: string[];
	},
	errorMessage?: string,
) => {
	return {
		type: addContactsToCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/contact`, values),
		payload: {
			custom: 'addContactToCampaing',
			successMessage: ToastMessage.contactsToCampaignAdded,
			errorMessage: errorMessage || ToastMessage.contactsToCampaignError,
		},
	};
};

export const getEmailTemplatesTypes = createFetchTypes(
	`${mainType}_GET_EMAIL_TEMPLATES`,
);

function getEmailTemplates(): fetchAction {
	return {
		type: getEmailTemplatesTypes,
		callAPI: () => axios.get(`${apiUrl}/workspaces/templates`),
		payload: {
			custom: 'emailTemplates',
		},
	};
}

function clearDraftResponse() {
	return {
		type: 'CLEAR_DRAFT_RESPONSE',
		payload: {},
	};
}

function resetCampaignState() {
	return {
		type: 'campaign/RESET',
	};
}

export const saveCampaignTypes = createFetchTypes(`${mainType}_SAVE_CAMPAIGN`);

function saveCampaign(
	saveCampaignParams: any,
	customName?: saveCampaignCustomName,
	successMessage?: string,
) {
	return {
		type: saveCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/save`, saveCampaignParams),
		payload: {
			custom: customName,
			successMessage:
				(customName === 'saveDraft' && ToastMessage.savedDraftCampaign) ||
				successMessage,
		},
	};
}

export const saveCurrentChildCampaignTypes = createFetchTypes(
	`${mainType}_SAVE_CHILD_CAMPAIGN`,
);

export const saveAndUpdateCurrentChildCampaignTypes = createFetchTypes(
	`${mainType}_SAVE_AND_UPDATE_CHILD_CAMPAIGN`,
);

function saveCurrentChildCampaign(
	saveChildCampaignParams: any,
	updateCurrentChild?: boolean,
) {
	return {
		type: updateCurrentChild
			? saveAndUpdateCurrentChildCampaignTypes
			: saveCurrentChildCampaignTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/campaign/save`, saveChildCampaignParams),
		payload: {
			custom: 'saveChild',
			successMessage: '',
			errorMessage: ToastMessage.error,
		},
	};
}

export const launchCampaignTypes = createFetchTypes(
	`${mainType}_LAUNCH_CAMPAIGN`,
);

function launchCampaign(campaignId: string) {
	return {
		type: launchCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/bulkLaunch/${campaignId}`),
		payload: {
			custom: 'launchCampaign',
			successMessage: ToastMessage.campaignLaunched,
			errorMessage: ToastMessage.error,
		},
	};
}

function editCampaign(campaign: any) {
	return {
		type: 'campaign/EDIT_CAMPAIGN',
		payload: {
			campaign,
		},
	};
}

export const saveCampaignBulkTypes = createFetchTypes(
	`${mainType}_SAVE_CAMPAIGN_BULK`,
);

function saveCampaignBulk(saveBulkParams: any) {
	return {
		type: saveCampaignBulkTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/bulk`, saveBulkParams),
		payload: {
			custom: 'saveCampaignBulk',
			successMessage: ToastMessage.savedDraftCampaign,
			errorMessage: ToastMessage.error,
		},
	};
}

export const duplicateCampaignTypes = createFetchTypes(
	`${mainType}_DUPLICATE_CAMPAIGN`,
);

function duplicateCampaign(campaignId: string) {
	return {
		type: duplicateCampaignTypes,
		callAPI: () => axios.post(`${apiUrl}/campaign/duplicate/${campaignId}`),
		payload: {
			custom: 'duplicateCampaign',
			errorMessage: ToastMessage.error,
		},
	};
}

function setCampaignTree(campaigns: any[]) {
	return {
		type: 'campaign/SET_CAMPAIGN_TREE',
		payload: campaigns,
	};
}

function setCampaignName(name: string) {
	return {
		type: 'campaign/SET_CAMPAIGN_NAME',
		payload: name,
	};
}
export {
	getCampaign,
	getCampaignsCount,
	filterCampaigns,
	deleteCampaigns,
	createCampaign,
	updateCampaign,
	createDraftCampaign,
	launchDraftCampaign,
	exportCampaigns,
	exportSingleCampaign,
	addContactToCampaign,
	clearDraftResponse,
	sendEmailPreview,
	getEmailTemplates,
	resendStoriesCount,
	resendCampaignStories,
	resetCampaignState,
	getCampaignOptions,
	deactivateCampaign,
	setCampaignName,
	//Campaign creation services
	saveCampaign,
	launchCampaign,
	editCampaign,
	saveCampaignBulk,
	setCampaignTree,
	saveCurrentChildCampaign,
	duplicateCampaign,
	bulkDeleteDraftCampaigns,
	getVerifiedStorytellers,
};
