import createFetchTypes from '../../utils/createFetchTypes';
import { apiUrl } from '../../constants/index';
import { fetchAction } from '../types';
import { FilterOptions } from '../../types/index';
// import { EditStoryOptionsTypes, CreateStoryOptionsTypes } from "./types/index";
import axios from '../../../global/axios';
const mainType = 'transaction';

export const filterTransactionTypes = createFetchTypes(`${mainType}_FILTER`);

function filterTransactions(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);

	return {
		type: filterTransactionTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/transactions${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
	};
}

export const exportTransactionTypes = createFetchTypes(`${mainType}_EXPORT`);

function exportTransactions(FilterOptions: FilterOptions): fetchAction {
	const filterString = JSON.stringify(FilterOptions);

	return {
		type: exportTransactionTypes,
		callAPI: () =>
			axios({
				url: `${apiUrl}/transactions/export${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
				method: 'GET',
				responseType: 'blob',
			}),
	};
}

export { filterTransactions, exportTransactions };
